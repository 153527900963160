import React from 'react'
import {Form} from "react-bootstrap";
import {BookLanguage} from "../../../routes/book/BookType";

interface Props {
    language: BookLanguage
    setLanguage: (language: BookLanguage) => void
}

const BookLanguageFormSelect: React.FC<Props> = ({language, setLanguage}) => {
    return (
        <Form.Select value={language} onChange={(e) => setLanguage(e.target.value as BookLanguage)}>
            <option value="KOREAN">🇰🇷 한국어</option>
            <option value="ENGLISH">🇺🇸 영어</option>
            <option value="JAPANESE">🇯🇵 일본어</option>
            <option value="CHINESE">🇨🇳 중국어</option>
            <option value="FRENCH">🇫🇷 프랑스어</option>
            <option value="SPANISH">🇪🇸 스페인어</option>
            <option value="GERMAN">🇩🇪 독일어</option>
            <option value="RUSSIAN">🇷🇺 러시아어</option>
            <option value="ITALIAN">🇮🇹 이탈리아어</option>
            <option value="UNKNOWN">기타</option>
        </Form.Select>
    )
}

export default BookLanguageFormSelect
