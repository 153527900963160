import {useEffect, useState} from "react";
import BookStatisticsLanguageResponse from "../responses/BookStatisticsLanguageResponse";
import {BooksitoutServer} from "../../../../config/BooksitoutServer";

const useBookStatisticsLanguage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [languages, setLanguages] = useState<BookStatisticsLanguageResponse | null>(null)
    const [totalCount, setTotalCount] = useState<number>(0)

    useEffect(() => {
        BooksitoutServer
            .get(`/v1/statistics/languages`)
            .then((res) => setLanguages(res.data))
            .finally(() => setIsLoading(false))
    }, [])

    useEffect(() => {
        setTotalCount(
            (languages?.korean ?? 0) +
            (languages?.english ?? 0) +
            (languages?.japanese ?? 0) +
            (languages?.chinese ?? 0) +
            (languages?.arabic ?? 0) +
            (languages?.spanish ?? 0) +
            (languages?.french ?? 0) +
            (languages?.german ?? 0) +
            (languages?.russian ?? 0)
        )
    }, [languages]);

    return [languages, totalCount, isLoading] as const
}

export default useBookStatisticsLanguage
