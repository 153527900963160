import styled from 'styled-components';
import React, {useEffect, useState} from 'react'
import Modal from "../../../common/Modal";
import SearchBookResponse from '../../search/book/SearchBookResponse';
import {Form} from "react-bootstrap";
import {BookForm, BookLanguage, BookSource} from '../BookType';
import {BooksitoutServer} from "../../../config/BooksitoutServer";
import toast from "react-hot-toast";
import {useNavigate} from 'react-router-dom';
import RowSpacer from "../../../common/styles/RowSpacer";
import utils from '../../../common/utils';
import NumberInput from "../../../common/form/NumberInput";
import BookSourceFormSelect from "../../../common/form/book/BookSourceFormSelect";
import BookLanguageFormSelect from "../../../common/form/book/BookLanguageFormSelect";
import BookFormFormSelect from "../../../common/form/book/BookFormFormSelect";
import BooksitoutButton from "../../../common/button/BooksitoutButton";

interface Props {
    isAddModalOpen: boolean
    onClose: () => void
    selectedBook: SearchBookResponse | null
}

const AddBookSearchModal: React.FC<Props> = ({isAddModalOpen, onClose, selectedBook}) => {
    return (
        <Modal
            isShowing={isAddModalOpen}
            onClose={onClose}
            titleText={'내 서재에 책 추가하기'}
            isPreventClose={false}
            size={'lg'}
            body={
                <Container>
                    {selectedBook != null && <AddBookSearchModalBody book={selectedBook}/>}
                </Container>
            }
        />
    )
}

interface BookAddRequest {
    isbn: string | null
    title: string | null
    author: string | null
    cover: string | null
    page: number | null

    source: BookSource
    form: BookForm
    language: BookLanguage

    isPublic: boolean
}

interface PropsBody {
    book: SearchBookResponse
}

const AddBookSearchModalBody: React.FC<PropsBody> = ({book}) => {
    const navigate = useNavigate()

    useEffect(() => {
        if (book.page == null && book.isbn13 != null) {
            BooksitoutServer
                .get(`/v1/book-isbn/${book.isbn13}`)
                .then(res => {
                    setPage(res.data.page)
                    setLanguage(res.data.language)
                })
        }
    }, [book]);

    const [title, setTitle] = useState<string>(book.title)
    const [author, setAuthor] = useState<string>(book.authors)
    const [cover] = useState<string>(book.cover)
    const [page, setPage] = useState<string | null>(book.page?.toString() ?? null)
    const [source, setSource] = useState<BookSource>('BUY_NEW_OFFLINE')
    const [form, setForm] = useState<BookForm>('PHYSICAL')
    const [language, setLanguage] = useState<BookLanguage>(book.language)
    const [isPublic, setIsPublic] = useState<boolean>(true)

    const handleSubmit = (e: React.FormEvent<HTMLFormElement> | null) => {
        if (e) {
            e.preventDefault()
        }

        if (page == null || !utils.isNumber(page)) {
            toast.error('페이지 수를 입력해주세요.')
            return
        }

        const request: BookAddRequest = {
            isbn: book.isbn13,
            title: title,
            author: author,
            cover: cover,
            page: parseInt(page),
            source: source,
            form: form,
            language: language,
            isPublic: isPublic
        }

        BooksitoutServer
            .post(`/v1/book`, request)
            .then((res) => {
                toast.success('책을 추가했어요.')
                navigate(`/book/mine/${res.data.book.id}`)
            })
            .catch(() => toast.error('책을 추가할 수 없었어요. 잠시 후 다시 시도해주세요.'))
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col2>
                    <Form.Label>제목</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="제목"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </Col2>
                <Col2>
                    <Form.Label>작가</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="작가"
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                    />
                </Col2>
                <RowSpacer size={10}/>

                <div>
                    <Form.Label>페이지 수 </Form.Label>
                    <NumberInput
                        placeholder="페이지 수"
                        isFocusOnAppear={page == null}
                        min={1}
                        max={10_000}
                        value={page?.toString()}
                        onChange={(e) => setPage(e.target.value)}
                    />
                </div>
                <RowSpacer size={10}/>

                <Col4>
                    <Form.Label>언어</Form.Label>
                    <BookLanguageFormSelect language={language} setLanguage={setLanguage}/>
                </Col4>

                <Col4>
                    <Form.Label>형태</Form.Label>
                    <BookFormFormSelect form={form} setForm={setForm} setSource={setSource}/>
                </Col4>

                <Col4>
                    <Form.Label>얻은 곳</Form.Label>
                    <BookSourceFormSelect form={form} source={source} setSource={setSource}/>
                </Col4>
                <RowSpacer/>

                <Form.Group className='mb-5' controlId='formBasicCheckbox'>
                    <Form.Check
                        type='switch'
                        label='내 책 정보 공개하기'
                        checked={isPublic}
                        onChange={() => setIsPublic(!isPublic)}
                        className='force-1-line'
                    />
                </Form.Group>

                <Col2>
                    <BooksitoutButton message={'책 추가하기'} onClick={() => handleSubmit(null)}/>
                </Col2>
            </Row>
        </Form>
    )
}

const Container = styled.div`
    min-height: 250px
`

const Row = styled.div.attrs({
    className: 'row'
})`
    justify-content: center;
`

const Col2 = styled.div.attrs({
    className: 'col-12 col-md-6'
})``

const Col4 = styled.div.attrs({
    className: 'col-12 col-md-4'
})``

export default AddBookSearchModal
