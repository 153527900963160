import React, {useCallback, useEffect} from "react";
import styled from 'styled-components';
import BookResponse from "../BookResponse";
import useReadingSessionStore from './useReadingSessionStore';
import {BooksitoutServer} from "../../../config/BooksitoutServer";
import useCurrentReadingSession from "./useCurrentReadingSession";
import toast from "react-hot-toast";
import BooksitoutIcon from "../../../config/BooksitoutIcon";
import ColSpacer from "../../../common/styles/ColSpacer";
import LoadingSpin from "../../../common/LoadingSpin";

interface Props {
    book: BookResponse
    size?: number
}

const BookReadingSessionButton: React.FC<Props> = ({book, size}) => {
    const {start, openTimerModal, currentReadingSession, updateCurrentReadingSession} = useReadingSessionStore()

    const {currentReadingSession: currentReadingSessionFromServer, refresh} = useCurrentReadingSession()
    useEffect(() => {
        updateCurrentReadingSession(currentReadingSessionFromServer)
    }, [currentReadingSessionFromServer, updateCurrentReadingSession])

    let onClick = useCallback((e: any) => {
        e.preventDefault()

        if (currentReadingSession?.readingSession == null) {
            BooksitoutServer
                .post(`/v1/book/${book.id}/reading-session/start`)
                .then((res) => {
                    start(book.id, res.data.id)
                    refresh()
                })
                .catch(() => {
                    toast.error('일시적으로 독서활동을 시작할 수 없어요. 잠시 후 다시 시도해주세요.')
                })
        } else if (currentReadingSession.book?.id != book.id) {
            toast.error('이미 진행중인 독서활동이 있어요')
            refresh()
            openTimerModal()
        } else {
            openTimerModal()
        }
    }, [currentReadingSession, book.id, start, updateCurrentReadingSession])

    const renderButtonContent = () => {
        if (currentReadingSession?.readingSession == null) {
            return (
                <>
                    <BooksitoutIcon.book className="mt-1"/>
                    <ColSpacer size={5}/>
                    이어서 읽기
                </>
            )
        }

        return (
            <>
                <div><LoadingSpin color="white" size="sm"/></div>
                <ColSpacer size={5}/>
                {
                    book.id === currentReadingSession.book?.id
                        ? "이 책 읽는 중"
                        : "다른 책 읽는 중"
                }
            </>
        )
    }

    return (
        <Button onClick={onClick} size={size}>
            <div className={'d-flex justify-content-center'}>
                <div className="d-flex justify-content-center">{renderButtonContent()}</div>
            </div>
        </Button>
    )
}

interface ButtonProps {
    size?: number;
}

const Button = styled.button.attrs({
    className: 'btn btn-book',
})<ButtonProps>`
    margin-top: 10px;
    width: 100%;

    ${props => props.size && `max-width: ${props.size}px;`}
`;

export default BookReadingSessionButton
