import {useState} from 'react'
import {Card, Form} from 'react-bootstrap'
import BookStatisticsTable from '../BookStatisticsTable'

const BookStatisticsByYearCard = () => {
    const currentYear = new Date().getFullYear()
    const [selectedYear, setSelectedYear] = useState<number>(currentYear)

    return (
        <Card className='h-100' style={{minHeight: '360px'}}>
            <Card.Body className='h-100'>
                <div className='text-decoration-none text-black h-100'>
                    <div className={'row mb-2'}>
                        <div className={'col-8'}>
                            <h3 className={'clamp-1-line'}>2024년 독서 요약</h3>
                        </div>

                        <div className={'col-4'}>
                            <Form.Select onChange={(e) => setSelectedYear(Number(e.target.value))}>
                                {
                                    Array.from({length: 10}, (_, i) => (
                                        <option key={currentYear - i}
                                                value={currentYear - i}>{currentYear - i}년</option>
                                    ))
                                }
                            </Form.Select>
                        </div>
                    </div>

                    <div className='h-100'>
                        <div className='d-flex align-items-center' style={{marginBottom: '10px'}}>
                            <BookStatisticsTable year={selectedYear}/>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default BookStatisticsByYearCard
