import React, {useEffect, useState} from 'react'
import styled from 'styled-components';

import 'draft-js/dist/Draft.css'
import {DefaultDraftBlockRenderMap, Editor, EditorState, Modifier, RichUtils,} from 'draft-js'
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import {Map} from 'immutable';

import './TextForm.scss'

import TextFormKeyBindingFn from './TextFormKeyBindingFn';
import TextFormEditorButton from './TextFormEditorButton'
import TextFormButton from './TextFormButton';
import TextFormBlockStyleFn from "./TextFormBlockStyleFn";
import RowSpacer from "../../styles/RowSpacer";

interface Props {
    placeholder: string,
    initialContent?: string,
    setContent: (content: string) => void,
    height?: number,
    autoFocus?: boolean,
}

const TextForm: React.FC<Props> = ({placeholder, initialContent, setContent, height = 300, autoFocus = true}) => {
    const editor = React.useRef<Editor>(null)
    const [editorState, setEditorState] = useState(() => (initialContent?.replace(/\n/g, '') || '') ? EditorState.createWithContent(stateFromHTML(initialContent?.replace(/\n/g, '') || '')) : EditorState.createEmpty())

    const [isShowPlaceholder, setIsShowPlaceholder] = React.useState<boolean>(true)
    const [labelLanguage, setLabelLanguage] = React.useState<'english' | 'korean'>('korean')

    useEffect(() => {
        if (editor.current && autoFocus) editor.current.focus()
    }, [autoFocus])
    useEffect(() => {
        const options = {
            inlineStyles: {
                HIGHLIGHT: {
                    style: {
                        backgroundColor: '#ECECEA',
                        color: '#CE5858',
                        padding: '2.5px',
                        margin: '2.5px',
                        borderRadius: '4px',
                    },
                },
            },
        }

        setIsShowPlaceholder(editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() !== 'unordered-list-item')

        const textInHtml = stateToHTML(editorState.getCurrentContent(), options)
        setContent(textInHtml)
    }, [editorState])

    const customStyleMap = {
        HIGHLIGHT: {
            backgroundColor: '#ECECEA',
            color: '#CE5858',
            padding: '2.5px',
            margin: '2.5px',
            borderRadius: '4px',
        },
    }
    const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(Map({
        'unordered-list-item': {
            element: 'li',
            wrapper: <ul className="editor-ul"/>
        },
        'ordered-list-item': {
            element: 'li',
            wrapper: <ol className="editor-ol"/>
        },
        'HIGHLIGHT': {
            element: 'span'
        }
    }))
    const labelMap = {
        korean: {
            quote: '인용',
            list: '리스트',
            bold: '굵게',
            italic: '기울게',
            underline: '밑줄',
            highlight: '강조',
        },
        english: {
            quote: 'Quote',
            list: 'List',
            bold: 'B',
            italic: 'I',
            underline: 'U',
            highlight: 'H',
        },
    }
    const activeStyleMap = {
        quote: () => editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'blockquote',
        list: () => editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'unordered-list-item',
        bold: () => editorState.getCurrentInlineStyle().has('BOLD'),
        underline: () => editorState.getCurrentInlineStyle().has('UNDERLINE'),
        italic: () => editorState.getCurrentInlineStyle().has('ITALIC'),
        highlight: () => editorState.getCurrentInlineStyle().has('HIGHLIGHT'),
    }

    const onChange = (newEditorState: EditorState) => {
        // Prevent auto-block conversion during IME composition
        if (newEditorState.isInCompositionMode()) {
            setEditorState(newEditorState);
            return;
        }

        const selection = newEditorState.getSelection()
        const currentContent = newEditorState.getCurrentContent()
        const key = selection.getStartKey()
        const block = currentContent.getBlockForKey(key)
        const text = block.getText()
        const blockType = block.getType()

        // Check if the block starts with '- '
        if (text.startsWith('- ')) {
            let newContentState = currentContent

            if (blockType !== 'unordered-list-item') {
                newContentState = Modifier.setBlockType(
                    newContentState,
                    selection,
                    'unordered-list-item'
                )
            }

            const newText = text.slice(2)
            newContentState = Modifier.replaceText(
                newContentState,
                selection.merge({anchorOffset: 0, focusOffset: 2}),
                newText
            )

            const newState = EditorState.push(newEditorState, newContentState, 'change-block-data')
            setEditorState(newState)
            return
        }

        // Check if the block starts with empty space (' ') and replace with quote
        if (text.startsWith(' ')) {
            let newContentState = currentContent

            if (blockType !== 'blockquote') {
                newContentState = Modifier.setBlockType(
                    newContentState,
                    selection,
                    'blockquote'
                )
            }

            const newText = text.slice(1)
            newContentState = Modifier.replaceText(
                newContentState,
                selection.merge({anchorOffset: 0, focusOffset: 1}),
                newText
            )

            const newState = EditorState.push(newEditorState, newContentState, 'change-block-data')
            setEditorState(newState)
            return
        }

        // If no conditions are met, maintain the current state
        setEditorState(newEditorState)
    }

    const handleOnToggleTextStyle = (style: string) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style))
    }
    const handleOnToggleBlockStyle = (blockType: string) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType))
    }
    const handleReturn = (event: any, editorState: EditorState) => {
        const contentState = editorState.getCurrentContent()
        const selectionState = editorState.getSelection()
        contentState.getBlockForKey(selectionState.getStartKey())
        return 'not-handled'
    }
    const handleShortcut = (command: string, editorState: EditorState) => {
        if (command === 'HIGHLIGHT') {
            handleOnToggleTextStyle(command)
            return 'handled'
        }

        const newState = RichUtils.handleKeyCommand(editorState, command)
        if (newState) {
            onChange(newState)
            return 'handled'
        }

        return 'not-handled'
    }
    const handleFocusEditor = () => {
        if (editor && editor.current) {
            editor.current.focus()
        }
    }

    return (
        <EditorFormContainer
            height={height}
            onClick={handleFocusEditor}
            className="border rounded p-2"
        >
            <TextFormButton labelLanguage={labelLanguage} setLabelLanguage={setLabelLanguage}/>

            <BlockButtonContainer>
                <TextFormEditorButton
                    label={labelLanguage === 'english' ? labelMap.english.quote : labelMap.korean.quote}
                    style="blockquote"
                    active={activeStyleMap.quote()}
                    onToggleStyle={handleOnToggleBlockStyle}
                />
                <TextFormEditorButton
                    label={labelLanguage === 'english' ? labelMap.english.list : labelMap.korean.list}
                    style="unordered-list-item"
                    active={activeStyleMap.list()}
                    onToggleStyle={handleOnToggleBlockStyle}
                />
            </BlockButtonContainer>
            <RowSpacer size={5}/>
            <TextButtonContainer>
                <TextFormEditorButton
                    label={labelLanguage === 'english' ? labelMap.english.bold : labelMap.korean.bold}
                    style="BOLD"
                    active={activeStyleMap.bold()}
                    onToggleStyle={handleOnToggleTextStyle}
                />
                <TextFormEditorButton
                    label={labelLanguage === 'english' ? labelMap.english.italic : labelMap.korean.italic}
                    style="ITALIC"
                    active={activeStyleMap.italic()}
                    onToggleStyle={handleOnToggleTextStyle}
                />
                <TextFormEditorButton
                    label={labelLanguage === 'english' ? labelMap.english.underline : labelMap.korean.underline}
                    style="UNDERLINE"
                    active={activeStyleMap.underline()}
                    onToggleStyle={handleOnToggleTextStyle}
                />
                <TextFormEditorButton
                    label={labelLanguage === 'english' ? labelMap.english.highlight : labelMap.korean.highlight}
                    style="HIGHLIGHT"
                    active={activeStyleMap.highlight()}
                    onToggleStyle={handleOnToggleTextStyle}
                />
            </TextButtonContainer>
            <hr className="m-3"/>

            <EditorContainer height={height}>
                {/* @ts-ignore */}
                <Editor
                    ref={editor}
                    placeholder={isShowPlaceholder && placeholder}
                    onChange={onChange}

                    editorState={editorState}
                    handleReturn={handleReturn}
                    handleKeyCommand={handleShortcut}

                    customStyleMap={customStyleMap}
                    blockRenderMap={extendedBlockRenderMap}

                    keyBindingFn={TextFormKeyBindingFn}
                    blockStyleFn={TextFormBlockStyleFn}
                />
            </EditorContainer>
        </EditorFormContainer>
    )
}

interface EditorContainerProps {
    height: number
}

const EditorContainer = styled.div<EditorContainerProps>`
    padding-left: 20px;
    padding-right: 20px;
    overflow: scroll;
    height: ${props => props.height}px;
`
const EditorFormContainer = styled.div<EditorContainerProps>`
    min-height: ${props => props.height}px;
    background-color: white;
    overflow-y: scroll;
`;
const TextButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
`
const BlockButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`

export default TextForm