import React, {useState} from 'react'
import {Button} from 'react-bootstrap'
import {useNavigate} from "react-router-dom";
import LoadingSpin from "../LoadingSpin";
import ColSpacer from "../styles/ColSpacer";

interface Props {
    message: string
    url?: string
    onClick?: () => void
}

const BooksitoutButton: React.FC<Props> = ({message, url, onClick}) => {
    const navigate = useNavigate()

    const [isClicked, setIsClicked] = useState<boolean>(false)

    const onClickHandler = (e) => {
        setIsClicked(true)

        if (url || onClick) {
            e?.preventDefault()
        }

        if (url) {
            navigate(url)
            return
        }

        if (onClick) {
            onClick()
            return
        }

        e?.submit()

        setTimeout(() => {
            setIsClicked(false)
        }, 3000)
    }

    return (
        <Button
            onSubmit={onClickHandler}
            variant={'book'}
            type={'submit'}
            disabled={isClicked}
            style={{height: '40px', padding: '0px 50px', width: '100%'}}
        >
            <div className={'d-flex justify-content-center'}>
                {
                    isClicked && <>
                        <div><LoadingSpin color="white" size="sm"/></div>
                        <ColSpacer size={5}/>
                    </>
                }
                {message}
            </div>
        </Button>
    )
}
export default BooksitoutButton
