import {useEffect, useState} from 'react'
import {toast} from 'react-hot-toast'
import utils from "../../common/utils"
import {BooksitoutServer} from "../../config/BooksitoutServer"

const useCurrentLocation = (isLocationRequested: boolean = true) => {
    const [lat, setLat] = useState<number | null>(null)
    const [long, setLong] = useState<number | null>(null)
    const [locationName, setLocationName] = useState<string | null>(null)
    const [isLocationError, setIsLocationError] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (isLocationRequested) getLocation().then(r => r)
    }, [isLocationRequested])

    const getLocation = async () => {
        try {
            const [lat, long, locationName] = await fetchLocation()

            if (lat !== null || lat === 0) {
                setLat(lat)
            }

            if (long !== null || long === 0) {
                setLong(long)
            }

            setLocationName(locationName)
            setIsLocationError(false)
            setIsLoading(false)
        } catch (error) {
            setIsLocationError(true)
            setIsLoading(false)
            toast.error('위치를 가져올 수 없었어요. 잠시 후 다시 시도해 주세요')
        }
    }

    const refreshLocation = async () => {
        setIsLoading(true)
        toast.loading('위치를 가져오고 있어요')

        try {
            const [lat, long, locationName] = await fetchLocationNoCache()

            setLat(lat)
            setLong(long)
            setLocationName(locationName ?? '?')
            setIsLocationError(false)
            setIsLoading(false)
            toast.success('위치를 업데이트 했어요')
        } catch (error) {
            setIsLocationError(true)
            setIsLoading(false)
            toast.error('위치를 가져올 수 없었어요. 잠시 후 다시 시도해 주세요')
        }
    }

    return [lat, long, locationName, isLoading, isLocationError, refreshLocation] as const
}

const fetchLocation = async (): Promise<[number, number, string]> => {
    const [lat, long, address] = await locationService.get()

    if (lat === null || long === null) {
        throw new Error('Location fetch failed')
    }

    return [lat, long, address]
}

const fetchLocationNoCache = async (): Promise<[number, number, string]> => {
    const [lat, long, address] = await locationService.getNoCache()

    if (lat === null || long === null) {
        throw new Error('Location fetch failed')
    }

    return [lat, long, address]
}

const LOCATION_DATA_KEY = 'location-data'

const locationService = {
    async get(): Promise<[number, number, string]> {
        const cachedData = localStorage.getItem(LOCATION_DATA_KEY)

        if (cachedData) {
            const {latitude, longitude, locationName, timestamp} = JSON.parse(cachedData)

            if (!utils.isHoursPassed(timestamp, 24) || !navigator.geolocation) {
                return [latitude, longitude, locationName]
            }
        }

        if (!navigator.geolocation) {
            return [0, 0, '?']
        }

        try {
            const position = await new Promise<GeolocationPosition>((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject)
            })

            const latitude = position.coords.latitude
            const longitude = position.coords.longitude
            const locationName = await this.getLocationName(latitude, longitude, false)

            const locationData = {
                latitude,
                longitude,
                locationName,
                timestamp: new Date().toISOString(),
            }
            localStorage.setItem(LOCATION_DATA_KEY, JSON.stringify(locationData))

            return [latitude, longitude, locationName]
        } catch {
            return [0, 0, '?']
        }
    },

    async getNoCache(): Promise<[number, number, string]> {
        if (!navigator.geolocation) {
            throw new Error('Geolocation not supported')
        }

        try {
            const position = await new Promise<GeolocationPosition>((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject)
            })

            const latitude = position.coords.latitude
            const longitude = position.coords.longitude
            const locationName = await this.getLocationName(latitude, longitude, false)

            return [latitude, longitude, locationName]
        } catch {
            throw new Error('Unable to retrieve geolocation')
        }
    },

    async getLocationName(lat: number, long: number, useCache = true): Promise<string> {
        if (useCache) {
            const cachedData = localStorage.getItem(LOCATION_DATA_KEY)

            if (cachedData) {
                const {latitude, longitude, locationName} = JSON.parse(cachedData)
                if (latitude === lat && longitude === long && locationName.trim() !== '') {
                    return locationName
                }
            }
        }

        try {
            const response = await BooksitoutServer.get(`/v1/location/display-name?lat=${lat}&long=${long}`)
            return response.data.shortAddress
        } catch {
            return '?'
        }
    },
}

export default useCurrentLocation