import React, {useEffect, useState} from 'react'
import Modal from "../../../../common/Modal";
import useBookDetailStore from "../useBookDetailStore";
import {Form} from "react-bootstrap";
import RowSpacer from "../../../../common/styles/RowSpacer";
import TextForm from "../../../../common/form/textForm/TextForm";
import AddButton from "../../../../common/button/AddButton";
import {BooksitoutServer} from "../../../../config/BooksitoutServer";
import toast from "react-hot-toast";

interface Props {
    isOpen: boolean
    close: () => void
}

const BookDetailReviewModal: React.FC<Props> = ({isOpen, close}) => {
    const {book, updateReview} = useBookDetailStore()
    const [review, setReview] = useState<string>(book?.review ?? '')
    useEffect(() => {
        setReview(book?.review ?? '')
    }, [book])

    const handleOnSubmit = (e) => {
        e.preventDefault()
        addOrEditReviewToServer()
    }

    const addOrEditReviewToServer = () => {
        if (book?.review === review) {
            toast.error('바뀐 내용이 없어요.')
            return
        }

        if (review.length > 1000) {
            toast.error('내용이 너무 길어요.')
            return
        }

        toast.loading('잠시만 기다려주세요...')

        const body = {review: review}

        BooksitoutServer
            .put(`/v1/book/${book?.id}`, body)
            .then(() => {
                updateReview(review)
                toast.success('내 감상을 추가/수정했어요.')
                close()
            })
            .catch(() => toast.error('오류가 났어요. 잠시 후 다시 시도해 주세요.'))
    }

    return (
        <Modal
            titleText={`내 감상 추가/수정하기`}
            isShowing={isOpen}
            onClose={close}
            body={
                <Form onSubmit={handleOnSubmit}>
                    <Form.Group>
                        <TextForm placeholder={'메모 내용'}
                                  initialContent={book?.review ?? ''}
                                  setContent={setReview}
                                  height={300}
                        />
                    </Form.Group>

                    <RowSpacer size={10}/>

                    <AddButton label={'수정하기'} onClick={addOrEditReviewToServer}/>
                </Form>
            }
        />
    )
}
export default BookDetailReviewModal
