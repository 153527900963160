import BooksitoutProductType from "../../booksitout/type/BooksitoutProductType";

import BooksitoutProductSubstrack from "./products/BooksitoutProductSubstrack";
import BooksitoutProductPurrfitTracker from "./products/BooksitoutProductPurrfitTracker";
import BooksitoutProductPianoman from "./products/BooksitoutProductPianoman";
import BooksitoutProductBooksitout from "./products/BooksitoutProductBooksitout";
import BooksitoutProductFeaturedDays from "./products/BooksitoutProductFeaturedDays";
import BooksitoutProductSimplifiedGoogleTask from "./products/BooksitoutProductSimplifiedGoogleTask";

const BooksitoutProductsData: BooksitoutProductType[] = [
    // Released
    BooksitoutProductBooksitout,
    BooksitoutProductSubstrack,
    BooksitoutProductPurrfitTracker,
    BooksitoutProductFeaturedDays,
    BooksitoutProductSimplifiedGoogleTask,

    // Working on it
    BooksitoutProductPianoman,
]

export default BooksitoutProductsData