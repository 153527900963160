import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import {useParams} from "react-router";
import Modal from "../../../../common/Modal";
import {Button, Form} from "react-bootstrap";
import RowSpacer from "../../../../common/styles/RowSpacer";
import {BooksitoutServer} from "../../../../config/BooksitoutServer";
import toast from "react-hot-toast";
import useBookDetailStore from "../useBookDetailStore";
import NumberInput from "../../../../common/form/NumberInput";
import DateForm from "../../../../common/form/DateForm";
import useReadingSessionStore from '../../reading/useReadingSessionStore';
import BooksitoutButton from "../../../../common/button/BooksitoutButton";

interface Props {
    isOpen: boolean
    close: () => void
}

const BookDetailReadingSessionAddModal: React.FC<Props> = ({isOpen, close}) => {
    const {bookId} = useParams()
    const {book, readingSessions, addReadingSession, updateBookStatus} = useBookDetailStore()
    const {playConfetti} = useReadingSessionStore()

    useEffect(() => {
        setStartPage(book?.currentPage?.toString() ?? null)
    }, [book]);

    const [startPage, setStartPage] = useState<string | null>(null)
    const [endPage, setEndPage] = useState<string | null>(null)
    const [readTimeInMinutes, setReadTimeInMinutes] = useState<string>('')
    const [year, setYear] = useState<number>(new Date().getFullYear())
    const [month, setMonth] = useState<number>(new Date().getMonth() + 1)
    const [day, setDay] = useState<number>(new Date().getDate())

    const addReadingSessionToServer = () => {
        const data = {
            startPage: startPage,
            endPage: endPage,
            readTimeInMinutes: readTimeInMinutes,
            year: year,
            month: month,
            day: day
        }

        BooksitoutServer
            .post(`/v1/book/${bookId}/reading-sessions`, data)
            .then((res) => {
                toast.success('독서활동을 추가했어요.')
                addReadingSession(res.data)
                close()
            })
            .catch(() => toast.error('오류가 났어요. 잠시 후 다시 시도해 주세요.'))
    }

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement> | null) => {
        e?.preventDefault()

        if (book == null) {
            return
        }

        if ((endPage == null || endPage === '') && readTimeInMinutes === '') {
            toast.error('독서시간이나 끝 페이지를 입력해 주세요.')
            return
        }

        addReadingSessionToServer()

        if (book.endPage <= Number(endPage)) {
            if (window.confirm('책을 다 읽으신거 같아요. 완료 처리할까요?')) {
                updateStatusToDone(year, month, day)
            }
        }

        setReadTimeInMinutes('')
        setEndPage(null)
    }

    // 독서시간 추측
    const [isReadTimeButtonDisabled, setIsReadTimeButtonDisabled] = useState<boolean>(true)
    useEffect(() => {
        if (startPage != null && endPage != null && Number(startPage) < Number(endPage) && readingSessions.length > 0 && (readTimeInMinutes == '' || readTimeInMinutes == '0')) {
            setIsReadTimeButtonDisabled(false)
        } else {
            setIsReadTimeButtonDisabled(true)
        }
    }, [startPage, endPage, readingSessions, readTimeInMinutes])
    const predictReadTime = () => {
        if (startPage == null || endPage == null || Number(startPage) >= Number(endPage) || readingSessions.length == 0) {
            toast.error(`정보가 충분하지 않아 독서시간을 추측할 수 없었어요. 독서활동을 더 기록하면 추측할 수 있어요.`)
            return
        }

        const totalPagesRead = readingSessions
            .filter((readingSession) => readingSession.startPage != null && readingSession.endPage != null)
            .reduce((acc, readingSession) => {
                return acc + (readingSession.endPage!! - readingSession.startPage!! + 1)
            }, 0)

        const totalTimeRead = readingSessions
            .filter((readingSession) => readingSession.startPage != null && readingSession.endPage != null)
            .reduce((acc, readingSession) => acc + readingSession.readTimeInMinutes, 0)

        const averageReadTimePerPage = totalTimeRead / totalPagesRead

        setReadTimeInMinutes(String(Math.floor((Number(endPage) - Number(startPage) + 1) * averageReadTimePerPage)))

        toast.success(`그 전 독서활동을 바탕으로 독서시간을 추측했어요.`)
    }

    // 페이지 추측
    const [isEndPageButtonDisabled, setIsEndPageButtonDisabled] = useState<boolean>(true)
    useEffect(() => {
        if (startPage != null && readTimeInMinutes != null && readTimeInMinutes != '0' && readTimeInMinutes != '' && (endPage == null || endPage == '')) {
            setIsEndPageButtonDisabled(false)
        } else {
            setIsEndPageButtonDisabled(true)
        }
    }, [startPage, endPage, readTimeInMinutes])
    const predictEndPage = () => {
        if (startPage == null || readTimeInMinutes == null || readTimeInMinutes == '0') {
            toast.error(`정보가 충분하지 않아 페이지를 추측할 수 없었어요.`)
            return
        }

        const totalPagesRead = readingSessions
            .filter((readingSession) => readingSession.startPage != null && readingSession.endPage != null)
            .reduce((acc, readingSession) => {
                return acc + (readingSession.endPage!! - readingSession.startPage!! + 1)
            }, 0)

        const totalTimeRead = readingSessions
            .filter((readingSession) => readingSession.startPage != null && readingSession.endPage != null)
            .reduce((acc, readingSession) => acc + readingSession.readTimeInMinutes, 0)

        const averagePagePerMinute = totalPagesRead / totalTimeRead

        setEndPage(String(Number(startPage) + Math.floor(Number(readTimeInMinutes) * averagePagePerMinute) - 1))

        toast.success(`그 전 독서활동을 바탕으로 페이지를 추측했어요`)
    }

    const updateStatusToDone = (year: number, month: number, day: number) => {
        if (book == null) {
            toast.error(`책 정보를 불러오지 못했어요. 잠시 후 다시 시도해 주세요.`)
            return
        }

        BooksitoutServer
            .put(`/v1/book/${book.id}`, {
                status: 'DONE',
                doneDate: `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`
            })
            .then(() => {
                playConfetti()
                updateBookStatus('DONE')
                toast.success('책을 다 읽었어요! 축하 드려요! 👏')
            })
            .catch(() => toast.error(`오류가 났어요. 잠시 후 다시 시도해 주세요.`))
    }

    return (
        <Modal
            isShowing={isOpen}
            onClose={close}
            titleText={'독서활동 추가하기'}
            size={'lg'}
            body={
                <Form onSubmit={handleOnSubmit}>
                    <Form.Group>
                        <Form.Label>독서시간 (분)</Form.Label>

                        <Row>
                            <Col10>
                                <NumberInput
                                    isFocusOnAppear
                                    placeholder={'독서시간 (분)'}
                                    min={1}
                                    max={1000}
                                    value={readTimeInMinutes}
                                    onChange={(e) => setReadTimeInMinutes(e.target.value)}
                                />
                            </Col10>

                            <Col2>
                                <Button
                                    disabled={isReadTimeButtonDisabled}
                                    variant={'book'}
                                    className={'w-100'}
                                    onClick={() => predictReadTime()}
                                >
                                    추측하기
                                </Button>
                            </Col2>
                        </Row>
                    </Form.Group>
                    <RowSpacer size={10}/>

                    <Form.Group>
                        <Form.Label>시작 / 끝 페이지</Form.Label>

                        <Row>
                            <Col5>
                                <NumberInput
                                    placeholder='시작 페이지'
                                    value={startPage}
                                    min={1}
                                    max={(book?.endPage ?? 1) - 1}
                                    onChange={(e) => setStartPage(e.target.value)}
                                />
                            </Col5>

                            <Col5>
                                <NumberInput
                                    placeholder='끝 페이지'
                                    min={1}
                                    max={book?.endPage}
                                    value={endPage}
                                    onChange={(e) => setEndPage(e.target.value)}
                                />
                            </Col5>

                            <Col2>
                                <Button
                                    disabled={isEndPageButtonDisabled}
                                    variant={'book'}
                                    className={'w-100'}
                                    onClick={() => predictEndPage()}
                                >
                                    추측하기
                                </Button>
                            </Col2>
                        </Row>
                    </Form.Group>
                    <RowSpacer size={10}/>

                    <Form.Group>
                        <Form.Label>날짜</Form.Label>

                        <DateForm year={year} month={month} day={day} setYear={setYear} setMonth={setMonth}
                                  setDay={setDay}/>
                    </Form.Group>
                    <RowSpacer size={20}/>

                    <BooksitoutButton message={'추가하기'} onClick={() => handleOnSubmit(null)}/>
                    <button type={'submit'} className={'d-none'}/>
                </Form>
            }
        />
    )
}

const Row = styled.div.attrs({
    className: 'row'
})``

const Col2 = styled.div.attrs({
    className: 'col-12 col-md-2'
})``

const Col5 = styled.div.attrs({
    className: 'col-12 col-md-5'
})``

const Col10 = styled.div.attrs({
    className: 'col-12 col-md-10'
})``

export default BookDetailReadingSessionAddModal
