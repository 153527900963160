import {useEffect, useState} from 'react'
import styled from 'styled-components';
import Modal from '../../../common/Modal';
import useReadingSessionStore from "./useReadingSessionStore";
import {Button as BootstrapButton, Form} from "react-bootstrap";
import RowSpacer from "../../../common/styles/RowSpacer";
import {BooksitoutServer} from "../../../config/BooksitoutServer";
import toast from 'react-hot-toast';
import {useNavigate} from "react-router-dom";
import NumberInput from "../../../common/form/NumberInput";
import useBookDetailStore from "../detail/useBookDetailStore";
import useBookDetail from "../detail/useBookDetail";
import BookReadingSessionResponse from "./BookReadingSessionResponse";
import BooksitoutButton from "../../../common/button/BooksitoutButton";

const BookReadingSessionEndModal = () => {
    const {isEndModalOpen, closeEndModal} = useReadingSessionStore()

    return (
        <Modal
            titleText={'독서활동 끝내기'}
            isShowing={isEndModalOpen}
            onClose={closeEndModal}
            body={<Body/>}
            size={'md'}
        />
    )
}

const Body = () => {
    const navigate = useNavigate()

    const {
        currentReadingSession,
        stop,
        bookId, readingSessionId, timerInSeconds,
        updateCurrentReadingSession,
        playConfetti
    } = useReadingSessionStore()

    const {updateBookStatus} = useBookDetailStore()

    // @ts-ignore
    const [startPage, setStartPage] = useState<string | null>(currentReadingSession?.book?.currentPage != null ? String(currentReadingSession.book.currentPage) : null)
    const [endPage, setEndPage] = useState<string | null>()

    useEffect(() => {
        setStartPage(currentReadingSession?.book?.currentPage != null ? String(currentReadingSession.book.currentPage) : null)
    }, [currentReadingSession])

    const [bookDetail] = useBookDetail(Number(bookId))
    const [readingSessions, setReadingSessions] = useState<BookReadingSessionResponse[]>([])
    useEffect(() => {
        setReadingSessions(bookDetail?.readingSessions ?? [])
    }, [bookDetail])

    const {addReadingSession} = useBookDetailStore()

    // 페이지 추측
    const [isPredictButtonDisabled, setIsPredictButtonDisabled] = useState<boolean>(true)
    useEffect(() => {
        if (startPage != null && timerInSeconds != null && timerInSeconds > 60 && (endPage == null || endPage == '') && readingSessions.length > 0) {
            setIsPredictButtonDisabled(false)
        } else {
            setIsPredictButtonDisabled(true)
        }
    }, [startPage, endPage, timerInSeconds])

    const predictEndPage = () => {
        const readTimeInMinutes = timerInSeconds / 60

        if (!(startPage != null && timerInSeconds != null && timerInSeconds > 60 && (endPage == null || endPage == ''))) {
            toast.error(`정보가 충분하지 않아 페이지를 추측할 수 없었어요.`)
            return
        }

        const totalPagesRead = readingSessions
            .filter((readingSession) => readingSession.startPage != null && readingSession.endPage != null)
            .reduce((acc, readingSession) => {
                return acc + (readingSession.endPage!! - readingSession.startPage!! + 1)
            }, 0)

        const totalTimeRead = readingSessions
            .filter((readingSession) => readingSession.startPage != null && readingSession.endPage != null)
            .reduce((acc, readingSession) => acc + readingSession.readTimeInMinutes, 0)

        const averagePagePerMinute = totalPagesRead / totalTimeRead

        setEndPage(String(Number(startPage) + Math.floor(Number(readTimeInMinutes) * averagePagePerMinute) - 1))

        toast.success(`그 전 독서활동을 바탕으로 페이지를 추측했어요`)
    }

    const handleSave = (e) => {
        e?.preventDefault()

        const bookEndPage = currentReadingSession?.book?.endPage

        if (startPage != null && Number(startPage) < 0) {
            toast.error('시작 페이지는 0 이상이어야 해요.')
            return
        }

        if (endPage != null && Number(endPage) < 0) {
            toast.error('끝 페이지는 0 이상이어야 해요.')
            return
        }

        if (endPage != null && bookEndPage != null && Number(endPage) > bookEndPage) {
            toast.error('끝 페이지는 책의 마지막 페이지보다 작아야 해요.')
            return
        }

        let isLast = false
        if (endPage != null && bookEndPage != null && Number(endPage) >= bookEndPage) {
            isLast = window.confirm('책을 다 읽은 거 같아요. 완료 처리할까요?')
        }

        const body = {
            endTime: new Date(),
            readingTimeInMinutes: timerInSeconds / 60,
            startPage: startPage,
            endPage: endPage,
            isLast: isLast
        }

        BooksitoutServer
            .post(`/v1/book/reading-session/${readingSessionId}/end`, body)
            .then((res) => {
                stop()
                addReadingSession(res.data)
                updateCurrentReadingSession(null)

                if (isLast) {
                    playConfetti()
                    updateBookStatus('DONE')
                    toast.success('책을 다 읽었어요! 축하 드려요! 👏')
                } else {
                    toast.success('독서활동을 저장했어요')
                }

                navigate(`/book/mine/${bookId}`)
            })
            .catch(() => toast.error('오류가 났어요. 잠시 후 다시 시도해 주세요'))
    }

    const handleDelete = () => {
        BooksitoutServer
            .delete(`/v1/book/reading-session/end`)
            .then(() => {
                toast.success('독서활동을 삭제했어요.')
                stop()
                updateCurrentReadingSession(null)
                navigate(`/book/mine/${bookId}`)
            })
            .catch(() => toast.error('오류가 났어요. 잠시 후 다시 시도해 주세요'))
    }

    return (
        <Form onSubmit={handleSave}>
            <Row>
                <Col>
                    <h6>시작 페이지</h6>
                    <NumberInput
                        placeholder={'시작 페이지'}
                        onChange={(e) => setStartPage(e.target.value)}
                        isFocusOnAppear={false}
                        value={startPage}
                        min={0}
                        max={currentReadingSession?.book?.endPage}
                    />
                </Col>

                <Col>
                    <h6>끝 페이지</h6>
                    <NumberInput
                        placeholder={'끝 페이지'}
                        isFocusOnAppear={true}
                        min={0}
                        max={currentReadingSession?.book?.endPage}
                        value={endPage}
                        onChange={(e) => setEndPage(e.target.value)}
                    />
                </Col>
            </Row>

            <RowSpacer/>

            <div className={'d-flex justify-content-end'}>
                <BootstrapButton disabled={isPredictButtonDisabled} variant={'book'} onClick={predictEndPage}>읽은 시간으로
                    추측하기</BootstrapButton>
            </div>

            <RowSpacer size={5}/>

            <Row>
                <ColDelete>
                    {/* @ts-ignore */}
                    <Button variant={'book-danger'} onClick={handleDelete}>
                        삭제하기
                    </Button>
                </ColDelete>

                <ColSave>
                    <BooksitoutButton message={endPage == null || endPage == '' ? '페이지 없이 저장하기' : '저장하기'} onClick={() => handleSave(null)}/>
                </ColSave>
            </Row>
        </Form>
    )
}

const Row = styled.div.attrs({
    className: 'row mt-2 mt-md-0'
})`
    justify-content: right;
`

const Col = styled.div.attrs({
    className: 'col-12 col-md-6 mt-2'
})``

const ColDelete = styled.div.attrs({
    className: 'col-4 col-md-4 mt-2 mt-md-0'
})``

const ColSave = styled.div.attrs({
    className: 'col-12 col-md-8 mt-2 mt-md-0'
})``

const Button = styled(BootstrapButton).attrs({})`
    width: 100%;
`

export default BookReadingSessionEndModal
