import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import {useParams} from "react-router";
import useBookDetail from "../detail/useBookDetail";
import {BooksitoutServer} from "../../../config/BooksitoutServer";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {Card, Form} from "react-bootstrap";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import RouteContainer from "../../../common/styles/RouteContainer";
import RowSpacer from "../../../common/styles/RowSpacer";
import NumberInput from "../../../common/form/NumberInput";
import {BookForm, BookLanguage, BookSource} from "../BookType";
import BookLanguageFormSelect from '../../../common/form/book/BookLanguageFormSelect';
import BookFormFormSelect from '../../../common/form/book/BookFormFormSelect';
import BookSourceFormSelect from '../../../common/form/book/BookSourceFormSelect';
import useHtmlTitle from "../../../common/useHtmlTitle";
import BooksitoutImages from "../../../images/BooksitoutImages";
import BookEditCoverModal from "./BookEditCoverModal";
import BooksitoutButton from "../../../common/button/BooksitoutButton";

const BookEditRoute = () => {
    const navigate = useNavigate()
    useHtmlTitle('책 수정하기')

    const {bookId} = useParams()
    const [book, isLoading] = useBookDetail(Number(bookId))

    const editBookToServer = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const data = {
            title: title,
            author: author,
            cover: cover,
            page: endPage,
            isPublic: isPublic,
            language: language,
            form: form,
            source: source
        }

        BooksitoutServer
            .put(`/v1/book/${bookId}`, data)
            .then(() => {
                toast.success(`책을 수정했어요.`)
                navigate(`/book/mine/${bookId}`)
            })
    }

    const [title, setTitle] = useState<string>(book?.book.title || "")
    const [author, setAuthor] = useState<string>(book?.book.author || "")
    const [endPage, setEndPage] = useState<string>(book?.book.endPage.toString() || "")
    const [isPublic, setIsPublic] = useState<boolean>(book?.book.isPublic ?? true)
    const [source, setSource] = React.useState<BookSource>(book?.book?.source ?? 'BUY_NEW_OFFLINE')
    const [form, setForm] = React.useState<BookForm>(book?.book?.form ?? 'PHYSICAL')
    const [language, setLanguage] = React.useState<BookLanguage>(book?.book?.language ?? 'KOREAN')

    const [isCoverModalOpen, setIsCoverModalOpen] = useState<boolean>(false)
    const [cover, setCover] = useState<string | null>('')

    useEffect(() => {
        if (book != null) {
            setTitle(book.book.title)
            setAuthor(book.book.author)
            setCover(book?.book.cover)
            setEndPage(book.book.endPage.toString())
            setIsPublic(book.book.isPublic)
            setLanguage(book.book.language)
            setForm(book.book.form)
            setSource(book.book.source)
        }
    }, [book]);

    if (isLoading) {
        return <></>
    }

    return (
        <RouteContainer>
            {
                isCoverModalOpen &&
                <BookEditCoverModal
                    isOpen={isCoverModalOpen}
                    onClose={() => setIsCoverModalOpen(false)}
                    cover={cover}
                    setCover={setCover}
                    currentCover={book?.book?.cover ?? ''}
                    title={title}
                    author={author}
                />
            }

            <RowSpacer size={10}/>
            <Form onSubmit={editBookToServer}>
                <Card>
                    <CardBodyContentContainer>
                        <Row className={'clickable'} onClick={() => setIsCoverModalOpen(true)}>
                            <BookCover
                                src={cover == '' || cover == null ? BooksitoutImages.Placeholder.bookCover : cover}
                                alt="Book cover"/>
                        </Row>
                        <RowSpacer/>

                        <Form.Group>
                            <Form.Label>제목</Form.Label>
                            <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
                        </Form.Group>
                        <RowSpacer size={10}/>

                        <Form.Group>
                            <Form.Label>저자</Form.Label>
                            <Form.Control type="text" value={author} onChange={(e) => setAuthor(e.target.value)}/>
                        </Form.Group>
                        <RowSpacer size={10}/>

                        <Form.Group>
                            <Form.Label>페이지</Form.Label>
                            <NumberInput
                                value={endPage}
                                min={1}
                                max={10_000}
                                onChange={(e) => setEndPage(e.target.value)}
                            />
                        </Form.Group>
                        <RowSpacer size={10}/>

                        <Form.Label>책 정보</Form.Label>
                        <Row>
                            <Col4>
                                <BookLanguageFormSelect language={language} setLanguage={setLanguage}/>
                            </Col4>

                            <Col4>
                                <BookFormFormSelect form={form} setForm={setForm} setSource={setSource}/>
                            </Col4>

                            <Col4>
                                <BookSourceFormSelect form={form} source={source} setSource={setSource}/>
                            </Col4>
                        </Row>
                        <RowSpacer/>

                        <Form.Group className='mb-5' controlId='formBasicCheckbox'>
                            <Form.Check
                                type='switch'
                                label='내 책 정보 공개하기'
                                checked={isPublic}
                                onChange={() => setIsPublic(!isPublic)}
                                className='force-1-line'
                            />
                        </Form.Group>

                        <RowSpacer/>
                        <Row>
                            <ButtonContainer>
                                <BooksitoutButton message={'책 수정하기'}/>
                            </ButtonContainer>
                        </Row>
                    </CardBodyContentContainer>
                </Card>
            </Form>
            <RowSpacer/>
        </RouteContainer>
    )
}

const Row = styled.div.attrs({
    className: 'row'
})`
    justify-content: right;
`

const Col4 = styled.div.attrs({
    className: 'col-4'
})``

const BookCover = styled.img`
    max-height: 300px;
    min-height: 300px;
    object-fit: contain;
`

const ButtonContainer = styled.div.attrs({
    className: 'col-12 col-md-6'
})``

export default BookEditRoute
