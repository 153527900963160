import {useParams} from "react-router-dom"
import styled from 'styled-components';
import Loading from "../../../common/Loading"
import useTips from "./useTips"
import RowSpacer from "../../../common/styles/RowSpacer";
import RouteTitle from "../../../common/RouteTitle/RouteTitle";
import booksitoutIcon from "../../../config/BooksitoutIcon";
import RouteTitleConfig from "../../../config/RouteTitleConfig";
import RouteContainer from "../../../common/styles/RouteContainer";
import useHtmlTitle from "../../../common/useHtmlTitle";
import TipsContentCard from "./TipsContentCard";

const CommunityTipsDetailRoute = () => {
    const {tipsId} = useParams()
    const [tip, isLoading] = useTips(tipsId ? parseInt(tipsId, 10) : null)
    useHtmlTitle(tip?.title)

    if (isLoading) return <Loading size={0} message={""}/>

    return (
        <TipsDetailContainer>
            <RouteTitle
                icon={<booksitoutIcon.community/>}
                title={'커뮤니티'}
                subTitle={'책에 관한 다양한 소식과 사람들을 알아갈 수 있어요'}
                currentKey={'tips'}
                buttons={RouteTitleConfig.Community}
                rightUi={undefined}
            />

            <RowSpacer/>
            <TipsContentCard tip={tip}/>

            <RowSpacer/>
        </TipsDetailContainer>
    )
}

const TipsDetailContainer = styled(RouteContainer)`
    hr {
        margin-bottom: 50px;
    }
`;

export default CommunityTipsDetailRoute
