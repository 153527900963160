import styled from 'styled-components';
import {Card} from 'react-bootstrap'
import CardTitle from '../../../../common/styles/CardTitle'
import useBookStatisticsCategory from '../hooks/useBookStatisticsCategory';
import StatisticsTable from "../../../../common/StatisticsTable";
import StatisticsTableRow from "../../../../common/StatisticsTableRow";

import others from '../../../../images/book-classifications/categories/others.png'
import philosophy from '../../../../images/book-classifications/categories/philosophy.png'
import religion from '../../../../images/book-classifications/categories/religion.png'
import socialScience from '../../../../images/book-classifications/categories/social-science.png'
import naturalScience from '../../../../images/book-classifications/categories/natural-science.png'
import technology from '../../../../images/book-classifications/categories/technology.png'
import art from '../../../../images/book-classifications/categories/art.png'
import language from '../../../../images/book-classifications/categories/language.png'
import literature from '../../../../images/book-classifications/categories/literature.png'
import history from '../../../../images/book-classifications/categories/history.png'
import question from '../../../../images/book-classifications/categories/unknown.png'

const BookStatisticsCategoryCard = () => {
    const [categories, totalCount, isLoading] = useBookStatisticsCategory()

    const getPercent = (count: number | undefined, totalCount: number): string => {
        const validCount = count ?? 0;
        const percentageRaw = totalCount > 0 ? (validCount / totalCount) * 100 : 0;
        const precision = percentageRaw < 1 ? 1 : 0;
        const percentage = percentageRaw.toFixed(precision)

        if (validCount === 0) {
            return ''
        }

        return `${percentage}%`
    }

    const rows: StatisticsTableRow[] = [
        {
            id: 0,
            icon: others,
            name: '총류',
            value: categories?.othersCount?.toString() ?? '0',
            extraValue: getPercent(categories?.othersCount, totalCount)
        },
        {
            id: 1,
            icon: philosophy,
            name: '철학',
            value: categories?.philosophyCount?.toString() ?? '0',
            extraValue: getPercent(categories?.philosophyCount, totalCount)
        },
        {
            id: 2,
            icon: religion,
            name: '종교',
            value: categories?.religionCount?.toString() ?? '0',
            extraValue: getPercent(categories?.religionCount, totalCount)
        },
        {
            id: 3,
            icon: socialScience,
            name: '사회과학',
            value: categories?.socialScienceCount?.toString() ?? '0',
            extraValue: getPercent(categories?.socialScienceCount, totalCount)
        },
        {
            id: 4,
            icon: naturalScience,
            name: '자연과학',
            value: categories?.naturalScienceCount?.toString() ?? '0',
            extraValue: getPercent(categories?.naturalScienceCount, totalCount)
        },
        {
            id: 5,
            icon: technology,
            name: '기술',
            value: categories?.technologyCount?.toString() ?? '0',
            extraValue: getPercent(categories?.technologyCount, totalCount)
        },
        {
            id: 6,
            icon: art,
            name: '예술',
            value: categories?.artCount?.toString() ?? '0',
            extraValue: getPercent(categories?.artCount, totalCount)
        },
        {
            id: 7,
            icon: language,
            name: '언어',
            value: categories?.languageCount?.toString() ?? '0',
            extraValue: getPercent(categories?.languageCount, totalCount)
        },
        {
            id: 8,
            icon: literature,
            name: '문학',
            value: categories?.literatureCount?.toString() ?? '0',
            extraValue: getPercent(categories?.literatureCount, totalCount)
        },
        {
            id: 9,
            icon: history,
            name: '역사',
            value: categories?.historyCount?.toString() ?? '0',
            extraValue: getPercent(categories?.historyCount, totalCount)
        },
        {
            id: 10,
            icon: question,
            name: '?',
            value: categories?.unknownCount?.toString() ?? '0',
            extraValue: getPercent(categories?.unknownCount, totalCount)
        },
    ]

    return (
        <Container>
            <ContainerBody>
                <CardTitle icon={<></>} title={`장르별 독서 요약`} url={''}/>

                <div className={'h-100'}>
                    <div className={'d-flex align-items-center'} style={{marginBottom: '10px'}}>
                        <StatisticsTable isLoading={isLoading} rows={rows}/>
                    </div>
                </div>
            </ContainerBody>
        </Container>
    )
}

const Container = styled(Card)`
    height: 100%;
    min-height: 650px;
`

const ContainerBody = styled(Card.Body)`
    height: 100%;
`

export default BookStatisticsCategoryCard
