import React from "react";
import styled from 'styled-components';
import PopularBookResponse from './PopularBookResponse'
import IndexContentContainer from '../../index/IndexContentContainer';
import ColSpacer from '../../../common/styles/ColSpacer';
import ColorConfig from '../../../config/ColorConfig';
import BooksitoutIcon from "../../../config/BooksitoutIcon";

interface Props {
    popularBook: PopularBookResponse
}

const PopularBookListRow: React.FC<Props> = ({popularBook}) => {
    return (
        <IndexContentContainer href={popularBook.isbn != undefined ? `/book/${popularBook.isbn}` : popularBook.link}>
            {
                popularBook.isAdded &&
                (
                    <AddButtonContainer>
                        <h1 className={'text-book'}>
                            <BooksitoutIcon.check/>
                        </h1>
                    </AddButtonContainer>
                )
            }

            <Row style={{opacity: popularBook.isAdded ? '0.5' : '1.0'}}>

                <RankingBadge>{popularBook.ranking}</RankingBadge>
                <ColSpacer size={2.5}/>

                <CoverContainer>
                    <Cover src={popularBook.coverUrl}/>
                </CoverContainer>

                <ColSpacer size={5}/>

                <InfoContainer>
                    <Title>{popularBook.title}</Title>
                    <Author>{popularBook.author.substring(0, 20)}</Author>
                </InfoContainer>
            </Row>
        </IndexContentContainer>
    )
}

const Row = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;

const CoverContainer = styled.div`
    min-width: 75px;
    max-width: 75px;
    justify-content: center;
    text-align: center;
`;

interface CoverProps {
    src: string | null
}

const Cover = styled.img.attrs({
    className: 'img-fluid rounded',
})<CoverProps>`
    height: 80px;
`;

const Title = styled.h5.attrs({
    className: 'clamp-1-line'
})`
`;

const Author = styled.h6.attrs({
    className: 'text-secondary clamp-1-line'
})`
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;

    max-width: calc(100% - 100px);
`;

const RankingBadge = styled.div`
    right: 0;
    min-width: 25px;
    height: 25px;
    background-color: ${ColorConfig.Primary};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const AddButtonContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 5px;
`

export default PopularBookListRow
