import {Card} from 'react-bootstrap'
import CardTitle from '../../../common/styles/CardTitle'
import CardBodyContentContainer from '../../../common/styles/CardBodyContentContainer'
import {usePopularBooks} from './usePopularBooks'
import PopularBookListRow from './PopularBookListRow'
import CardTitleImageIcon from '../../../common/CardTitleImageIcon'
import PopularBookListRowLoading from './PopularBookListRowLoading'
import BooksitoutImages from "../../../images/BooksitoutImages";

const PopularBooksYes24Card = () => {
    const [isLoading, popularBooks] = usePopularBooks('YES24', 10)

    return (
        <Card>
            <CardBodyContentContainer height={1500}>
                <CardTitle icon={<CardTitleImageIcon logo={BooksitoutImages.WebsiteLogo.yes24}/>} title={'YES24 베스트셀러'}
                           url={'/community/popular-books/yes24'}/>

                {
                    isLoading ?
                        Array(10).fill(0)
                            .map((_, index) => <PopularBookListRowLoading key={index + 1} index={index + 1}/>)
                        :
                        popularBooks.map(book => <PopularBookListRow key={book.isbn} popularBook={book}/>)
                }
            </CardBodyContentContainer>
        </Card>
    )
}

export default PopularBooksYes24Card