import {Card} from 'react-bootstrap'
import CardTitle from '../../../common/styles/CardTitle'
import {usePopularBooks} from './usePopularBooks'
import CardBodyContentContainer from '../../../common/styles/CardBodyContentContainer'
import PopularBookListRow from './PopularBookListRow'
import CardTitleImageIcon from '../../../common/CardTitleImageIcon'
import PopularBookListRowLoading from './PopularBookListRowLoading'
import BooksitoutImages from "../../../images/BooksitoutImages";

const PopularBooksAladinCard = () => {
    const [isLoading, popularBooks] = usePopularBooks('ALADIN', 10)

    return (
        <Card>
            <CardBodyContentContainer height={1500}>
                <CardTitle icon={<CardTitleImageIcon logo={BooksitoutImages.WebsiteLogo.aladin}/>} title={'알라딘 베스트셀러'}
                           url={'/community/popular-books/aladin'}/>

                {
                    isLoading ?
                        Array(10).fill(0)
                            .map((_, index) => <PopularBookListRowLoading key={index + 1} index={index + 1}/>)
                        :
                        popularBooks.map((book) => <PopularBookListRow key={book.isbn} popularBook={book}/>)}
            </CardBodyContentContainer>
        </Card>
    )
}

export default PopularBooksAladinCard