import styled from 'styled-components';
import RouteContainer from '../../../common/styles/RouteContainer'
import RouteTitle from '../../../common/RouteTitle/RouteTitle'
import booksitoutIcon from '../../../config/BooksitoutIcon'
import RouteTitleConfig from '../../../config/RouteTitleConfig'
import RowSpacer from '../../../common/styles/RowSpacer'
import {useParams} from 'react-router-dom'
import {usePopularBooks} from './usePopularBooks'
import PopularBookListRow from './PopularBookListRow'
import CardTitleImageIcon from '../../../common/CardTitleImageIcon'
import CardTitle from '../../../common/styles/CardTitle'
import PopularBookListRowLoading from './PopularBookListRowLoading'
import BooksitoutImages from "../../../images/BooksitoutImages";
import useHtmlTitle from "../../../common/useHtmlTitle";

const PopularBooksProviderRoute = () => {
    const {provider} = useParams<{ provider: 'BOOKSITOUT' | 'YES24' | 'ALADIN' | 'KYOBO' }>()
    const [isLoading, popularBooks] = usePopularBooks(provider ?? 'ALADIN', 100)
    useHtmlTitle(`${provider?.toUpperCase() == 'BOOKSITOUT' ? '책잇아웃' : provider?.toUpperCase() == 'YES24' ? 'Yes24' : provider?.toUpperCase() == 'ALADIN' ? '알라딘' : '교보문고'} 베스트셀러 | 책잇아웃`)

    return (
        <RouteContainer>
            <RouteTitle
                icon={<booksitoutIcon.community/>}
                title={'인기책'}
                subTitle={'지금 책잇아웃에서 인기 있는 책을 만나보세요'}
                currentKey={'popular'}
                buttons={RouteTitleConfig.Community}
                rightUi={undefined}
            />

            <RowSpacer/>
            <Container>
                {
                    provider?.toUpperCase() === 'ALADIN' ?
                        <CardTitle icon={<CardTitleImageIcon logo={BooksitoutImages.WebsiteLogo.aladin}/>}
                                   title={'알라딘 베스트셀러'}
                                   url={''}/>
                        :
                        provider?.toUpperCase() === 'YES24' ?
                            <CardTitle icon={<CardTitleImageIcon logo={BooksitoutImages.WebsiteLogo.yes24}/>}
                                       title={'YES24 베스트셀러'}
                                       url={''}/>
                            :
                            provider?.toUpperCase() === 'KYOBO' ?
                                <CardTitle icon={<CardTitleImageIcon logo={BooksitoutImages.WebsiteLogo.kyobo}/>}
                                           title={'교보문고 베스트셀러'} url={''}/>
                                :
                                <CardTitle icon={<CardTitleImageIcon logo={BooksitoutImages.WebsiteLogo.booksitout}/>}
                                           title={'책잇아웃 베스트셀러'} url={''}/>
                }
            </Container>

            <RowSpacer/>
            <Row>
                {
                    isLoading ?
                        Array(100)
                            .fill(0)
                            .map((_, index) => index + 1)
                            .map(index => <Col><PopularBookListRowLoading index={index}/></Col>)
                        :
                        popularBooks.slice(0, 100).map(book =>
                            <Col>
                                <PopularBookListRow popularBook={book}/>
                            </Col>
                        )
                }
            </Row>
        </RouteContainer>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Row = styled.div.attrs({
    className: 'row'
})`
`;

const Col = styled.div.attrs({
    className: 'col-12'
})`
`;

export default PopularBooksProviderRoute