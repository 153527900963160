import {useEffect, useState, useRef} from 'react'
import styled from 'styled-components';
import {useParams} from "react-router";
import RouteContainer from '../../../common/styles/RouteContainer';
import {Card} from "react-bootstrap";
import useBookIsbn from "./useBookIsbn";
import NoContent from "../../../common/NoContent";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import RowSpacer from "../../../common/styles/RowSpacer";
import BookIsbnCard from "./BookIsbnCard";
import useUrlQuery from "../../../common/hooks/useUrlQuery";
import useLoginStore from "../../login/useLoginStore";
import searchCache from "../../search/searchBar/searchCache";
import {BooksitoutServer} from "../../../config/BooksitoutServer";
import ApiUrls from "../../../ApiUrls";
import IndexSearchBar from "../../index/card/IndexSearchBar";
import BookSourceOfflineLibrarySection from "../../search/bookSource/section/BookSourceOfflineLibrarySection";
import BookSourceOnlineLibrarySection from "../../search/bookSource/section/BookSourceOnlineLibrarySection";
import BookSourceSubscriptionSection from "../../search/bookSource/section/BookSourceSubscriptionSection";
import BookSourceOnlineUsedSection from "../../search/bookSource/section/BookSourceOnlineUsedSection";
import BookSourceOfflineUsedSection from "../../search/bookSource/section/BookSourceOfflineUsedSection";
import BookIsbnCardLoading from "./BookIsbnCardLoading";
import LoadingBar from "../../../common/LoadingBar";
import parse from "html-react-parser";
import useHtmlTitle from "../../../common/useHtmlTitle";
import BooksitoutIcon from '../../../config/BooksitoutIcon';
import breakpoints from "../../../config/Breakpoints";

const BookIsbnRoute = () => {
    const {isbn} = useParams()
    const query = useUrlQuery('q')
    const [book, isLoading, isError] = useBookIsbn(isbn ?? null)

    useHtmlTitle(book?.title ?? '?')

    const isLoggedIn = useLoginStore((state) => state.isLoggedIn())
    useEffect(() => {
        if (!isLoggedIn) {
            searchCache.updateCache(
                'search-book-histories',
                query?.toString() ?? '',
                `/search/${isbn}`
            )
        } else if (isbn != null && query != null) {
            const body = {
                isbn: isbn,
                query: query
            }

            BooksitoutServer
                .post(ApiUrls.Search.BookHistory.POST, body).then(r => {
            })
        }
    }, [isLoggedIn, isbn, query])

    if (isLoading) {
        return (
            <RouteContainer>
                <RowSpacer/>
                <IndexSearchBar/>

                <RowSpacer/>
                <BookIsbnCardLoading/>

                <RowSpacer/>
                <Card>
                    <CardBodyContentContainer height={400}>
                        <Container>
                            <Title>소개</Title>
                            <LoadingBar size={15}/>
                            <RowSpacer size={5}/>

                            <LoadingBar size={8}/>
                            <RowSpacer size={5}/>

                            <LoadingBar size={20}/>
                            <RowSpacer size={5}/>

                            <LoadingBar size={5}/>
                            <RowSpacer size={5}/>

                            <LoadingBar size={12}/>
                            <RowSpacer size={5}/>

                            <LoadingBar size={7}/>
                            <RowSpacer size={5}/>
                        </Container>
                    </CardBodyContentContainer>
                </Card>

                <RowSpacer/>
                <Card>
                    <CardBodyContentContainer height={400}>
                        <Container>
                            <Title>목차</Title>
                            <LoadingBar size={15}/>
                            <RowSpacer size={5}/>

                            <LoadingBar size={8}/>
                            <RowSpacer size={5}/>

                            <LoadingBar size={8}/>
                            <RowSpacer size={5}/>

                            <LoadingBar size={8}/>
                            <RowSpacer size={20}/>

                            <LoadingBar size={15}/>
                            <RowSpacer size={5}/>

                            <LoadingBar size={8}/>
                            <RowSpacer size={5}/>

                            <LoadingBar size={8}/>
                            <RowSpacer size={5}/>

                            <LoadingBar size={8}/>
                            <RowSpacer size={5}/>

                            <LoadingBar size={8}/>
                            <RowSpacer size={5}/>
                        </Container>
                    </CardBodyContentContainer>
                </Card>

                <RowSpacer/>
            </RouteContainer>
        )
    }

    if (book == null || isError || isbn === undefined) {
        return (
            <NoContent message={`찾으시는 책이 없어요<br/>(isbn = ${isbn})`}/>
        )
    }

    return (
        <RouteContainer>
            <RowSpacer/>
            <IndexSearchBar/>

            <RowSpacer/>
            <BookIsbnCard book={book}/>

            {
                book.description != null && book.description !== '' && (
                    <>
                        <RowSpacer/>
                        <CollapsableCard content={
                            <Container>
                                <Title>소개</Title>
                                {parse(book.description?.replaceAll("\n", "<br/>") ?? '?')}
                            </Container>
                        }/>
                    </>
                )
            }

            {
                book.tableOfContentsHtml && (
                    <>
                        <RowSpacer/>
                        <CollapsableCard content={
                            <Container>
                                <Title>목차</Title>

                                <TableOfContentsContainer>
                                    {parse(book.tableOfContentsHtml?.replaceAll("\n", "<br/>") ?? '?')}
                                </TableOfContentsContainer>
                            </Container>
                        }/>
                    </>
                )
            }

            <RowSpacer/>
            <BookSourceOfflineLibrarySection isbn={isbn}/>

            <RowSpacer/>
            <BookSourceOnlineLibrarySection isbn={isbn}/>

            <RowSpacer/>
            <BookSourceSubscriptionSection isbn={isbn}/>

            <RowSpacer/>
            <BookSourceOnlineUsedSection isbn={isbn}/>

            <RowSpacer/>
            <BookSourceOfflineUsedSection isbn={isbn}/>

            <RowSpacer/>
        </RouteContainer>
    )
}

const Container = styled.div.attrs({
    className: 'row'
})`
    height: 100%;
    padding: 20px;
`

const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0;
    margin-bottom: 15px;
`

const TableOfContentsContainer = styled.p`
    h1 {
        font-size: 1.3rem;
        font-weight: bold;
        margin-top: 30px;
        padding: 0;
    }
    
    h1 + br {
        display: none;
    }
    
    h2 {
        font-size: 1.1rem;
        margin-top: 30px;
        padding: 0;
    }

    h1:first-of-type {
        margin-top: 10px;
    }
    
    ul {
        list-style-position: outside;
    }
    
    li {
        @media (min-width: ${breakpoints.md}) {
            padding-left: 20px;
        }
    }
`

const CollapsableCard = ({content}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        const element = containerRef.current;

        if (element) {
            // Compare scrollHeight and clientHeight to detect overflow
            setIsOverflowing(element.scrollHeight > element.clientHeight);
        }
    }, [content]);

    return (
        <Card className="clickable" onClick={() => isOverflowing && setIsExpanded(!isExpanded)}>
            <CollapsableCardContainer
                ref={containerRef}
                height={isExpanded ? null : 400}
                isOverflowing={isOverflowing && !isExpanded}
            >
                {content}
                {isOverflowing && (
                    <CollapsableCardButton>
                        {
                            !isExpanded && <BooksitoutIcon.ArrowDown/>
                        }
                    </CollapsableCardButton>
                )}
            </CollapsableCardContainer>
        </Card>
    );
}

const CollapsableCardContainer = styled(CardBodyContentContainer)`
    position: relative;
    min-height: ${({height}) => (height ? `${height}px` : 'auto')};
    max-height: ${({height}) => (height ? `${height}px` : 'none')};
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        pointer-events: none;
        display: ${({isOverflowing}) => (isOverflowing ? 'block' : 'none')};
        opacity: ${({isOverflowing}) => (isOverflowing ? 1 : 0)};
    }
`;

const CollapsableCardButton = styled.div.attrs({
    className: 'text-book'
})`
    font-size: 1.5rem;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 1;
`;

export default BookIsbnRoute