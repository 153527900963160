import React from 'react'
import styled from 'styled-components';
import {BooksitoutCard, BooksitoutCardTitle} from "../BooksitoutCard";
import {Card} from "react-bootstrap";
import BooksitoutProductType from "../type/BooksitoutProductType";
import ColorConfig from "../../config/ColorConfig";
import useLanguage from "../../common/language/useLanguage";
import getLanguageValue from "../../common/language/LanguageInfo";

interface Props {
    product: BooksitoutProductType
}

const ProductDetailLanguageCard: React.FC<Props> = ({product}) => {
    const language = useLanguage()

    let TITLE = getLanguageValue(`ProductDetailLanguageCard.Title`, language)
    let ENGLISH = getLanguageValue(`ENGLISH`, language)
    let JAPANESE = getLanguageValue(`JAPANESE`, language)
    let KOREAN = getLanguageValue(`KOREAN`, language)

    return (
        <BooksitoutCard>
            <Card.Body>
                <BooksitoutCardTitle>{TITLE}</BooksitoutCardTitle>

                <ul>
                    <li>
                        <Link href={`/products/${product.name.url}?language=KOREAN`}>{KOREAN}</Link>
                    </li>

                    <li>
                        <Link href={`/products/${product.name.url}?language=ENGLISH`}>{ENGLISH}</Link>
                    </li>

                    <li>
                        <Link href={`/products/${product.name.url}?language=JAPANESE`}>{JAPANESE}</Link>
                    </li>
                </ul>
            </Card.Body>
        </BooksitoutCard>
    )
}

const Link = styled.a`
    text-decoration: none;
    color: ${ColorConfig.Primary};
    font-weight: bold;

    &:hover {
        color: rgb(26, 188, 156);
    }
`

export default ProductDetailLanguageCard
