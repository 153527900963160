import BooksitoutProductType from "../../../booksitout/type/BooksitoutProductType";
import BooksitoutProductStatus from "../../../booksitout/type/BooksitoutProductStatus";
import BooksitoutImages from "../../../images/BooksitoutImages";

const BooksitoutProductSimplifiedGoogleTask: BooksitoutProductType = {
    id: 'simplifiedGoogleTask',
        status: BooksitoutProductStatus.PRODUCTION,
    name: {
        english: 'Simplified Google Task',
        url: 'simplified-google-task',
    },
    icon: BooksitoutImages.WebsiteLogo.simplifiedGoogleTask,
    link: [
        {
            link: "https://chromewebstore.google.com/detail/simplified-google-task/afbfiknnjongdnpklecckkgfehdojmgi",
            icon: BooksitoutImages.WebsiteLogo.googleChrome,
            border: true
        }
    ],
    featuresCount: 2,
    demoImages: [],
    thumbnailImage: null,
    detailImage: null,
    privacyPolicyDates: ['2024-12-21']
}

export default BooksitoutProductSimplifiedGoogleTask