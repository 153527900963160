import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import RouteContainer from "../../../common/styles/RouteContainer";
import RouteTitle from "../../../common/RouteTitle/RouteTitle";
import booksitoutIcon from "../../../config/BooksitoutIcon";
import RouteTitleConfig from "../../../config/RouteTitleConfig";
import RowSpacer from "../../../common/styles/RowSpacer";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import {Card, Form} from "react-bootstrap";
import useBookSearchSettings from "../../search/bookSource/hooks/useBookSearchSettings";
import {BooksitoutServer} from "../../../config/BooksitoutServer";
import toast from "react-hot-toast";
import SearchBarWithHistoryAndSuggestion from "../../search/searchBar/SearchBarWithHistoryAndSuggestion";
import AddDeleteButton from "../../../common/button/AddDeleteButton";
import useLoginStore from "../../login/useLoginStore";

interface Toggle {
    id: string
    logo: string
    name: string
}

const SettingsSearchRoute = () => {
    const isLogged = useLoginStore((state) => state.isLoggedIn())
    useEffect(() => {
        if (!isLogged) {
            window.location.href = '/login'
        }
    }, [isLogged]);

    const [settings, refresh] = useBookSearchSettings()
    useEffect(() => {
        if (settings != null) {
            setLibrarySearchMethod(settings.librarySearchMethod ?? 'CURRENT_LOCATION')

            const regionToggles = settings.regionDetails.map((r) => ({
                id: r.id.toString(),
                logo: r.depth2.logo,
                name: r.depth2.koreanName,
            }))
            setRegions(regionToggles)

            const libraryToggles = settings.libraries.map((l) => ({
                id: l.id.toString(),
                logo: l.location.logo,
                name: l.name,
            }))
            setLibraries(libraryToggles)
        }
    }, [settings])

    const [librarySearchMethod, setLibrarySearchMethod] = useState<string>('CURRENT_LOCATION')
    useEffect(() => {
        if (settings != null && librarySearchMethod !== settings.librarySearchMethod) {
            BooksitoutServer
                .put(`/v1/book/search/settings`, {
                    librarySearchMethod: librarySearchMethod,
                    regionIds: settings?.regionDetails?.map((r) => r.id) ?? [],
                    libraryIds: settings?.libraries?.map((l) => l.id) ?? [],
                })
                .then(() => {
                    refresh()
                    toast.success('도서관 검색 방법을 변경했어요.')
                })
        }
    }, [librarySearchMethod])

    const [regions, setRegions] = useState<Toggle[]>([])
    useEffect(() => {
        if (
            settings != null &&
            (
                regions.length !== settings.regionDetails.length ||
                regions.some(region => !settings.regionDetails.some(detail => detail.id.toString() === region.id))
            )
        ) {
            BooksitoutServer
                .put(`/v1/book/search/settings`, {
                    librarySearchMethod: settings.librarySearchMethod,
                    regionIds: regions.map((r) => r.id),
                    libraryIds: settings?.libraries?.map((l) => l.id) ?? [],
                })
                .then(() => {
                    refresh()
                    toast.success('검색 지역을 변경했어요.')
                })
        }
    }, [regions])

    const [libraries, setLibraries] = useState<Toggle[]>([])
    useEffect(() => {
        if (
            settings != null &&
            (
                libraries.length !== settings.libraries.length ||
                libraries.some(library => !settings.libraries.some(l => l.id.toString() === library.id))
            )
        ) {
            BooksitoutServer
                .put(`/v1/book/search/settings`, {
                    librarySearchMethod: settings.librarySearchMethod,
                    regionIds: settings?.regionDetails?.map((r) => r.id) ?? [],
                    libraryIds: libraries.map((l) => l.id),
                })
                .then(() => {
                    refresh()
                    toast.success('검색 도서관을 변경했어요.')
                })
        }
    }, [libraries])

    return (
        <RouteContainer>
            <RouteTitle
                icon={<booksitoutIcon.settings/>}
                title={'프로필 설정'}
                currentKey={'SEARCH'}
                buttons={RouteTitleConfig.Settings}
            />
            <RowSpacer/>

            <SearchSettingsCard title={'도서관 검색 방법'} body={
                <>
                    <Form.Select value={librarySearchMethod} onChange={(e) => setLibrarySearchMethod(e.target.value)}>
                        <option value={'CURRENT_LOCATION'}>현재 위치 근처 도서관</option>
                        <option value={'SPECIFIC_REGIONS'}>내가 지정한 지역의 도서관</option>
                        <option value={'SPECIFIC_LIBRARIES'}>직접 선택한 도서관</option>
                    </Form.Select>
                    <RowSpacer/>
                </>
            }/>

            <SearchSettingsCard height={475} title={'지역 선택'} body={<SpacerContainer>
                <SearchBarWithHistoryAndSuggestion
                    autoCompleteApiUrl={`/v1/library/search/auto-complete?filter=REGION_DETAIL&size=5`}
                    searchHistoryApiUrl={null}
                    searchHistoryCacheKey={null}
                    placeholder={'지역 검색해 추가하기'}
                    isHandleSubmit={false}
                    handleQuerySuggestionClick={(suggestion) => setRegions([
                        ...regions,
                        {
                            id: suggestion.id!!,
                            logo: suggestion.imageUrl,
                            name: suggestion.name,
                        }
                    ])}
                    addFirstItemOnSubmit={(item) => {
                        let region = item as Toggle
                        if (regions.some(r => r.id === region.id)) {
                            toast.error('이미 추가된 지역이에요.')
                            return
                        }

                        setRegions([
                            ...regions,
                            {
                                id: region.id,
                                logo: region.logo,
                                name: region.name,
                            }
                        ])
                    }}
                    isShouldUpdateQueryParam={false}
                />

                <RowSpacer/>

                <div className={'row'}>
                    {
                        regions.map(region =>
                            <div key={region.id} className={'col-12 col-md-4 col-lg-3 col-xl-2'}>
                                <Card className={'mb-3'}>
                                    <CardBodyContentContainer>
                                        <div style={{position: 'absolute', top: '-5px', right: '0'}}
                                             onClick={() => setRegions(
                                                 regions.filter(r => r.id !== region.id)
                                             )}>
                                            <AddDeleteButton state={'DELETE'}/>
                                        </div>

                                        <div className={'d-flex justify-content-between align-items-center'}>
                                            <img src={region.logo} className={'img-fluid'} style={{height: '40px'}}/>
                                            <h6 className={'mt-2 fw-bold clamp-1-line'}>{region.name}</h6>
                                        </div>
                                    </CardBodyContentContainer>
                                </Card>
                            </div>
                        )
                    }
                </div>
            </SpacerContainer>}/>

            <SearchSettingsCard height={475} title={'개별 도서관 선택'} body={<SpacerContainer>
                <SearchBarWithHistoryAndSuggestion
                    autoCompleteApiUrl={`/v1/library/search/auto-complete?filter=LIBRARY&size=5`}
                    searchHistoryApiUrl={null}
                    searchHistoryCacheKey={null}
                    placeholder={'도서관 검색해 추가하기'}
                    isHandleSubmit={false}
                    handleQuerySuggestionClick={(suggestion) => setLibraries([
                        ...libraries,
                        {
                            id: suggestion.id!!,
                            logo: suggestion.imageUrl,
                            name: suggestion.name,
                        }
                    ])}
                    addFirstItemOnSubmit={(item) => {
                        let library = item as Toggle
                        if (libraries.some(l => l.id === library.id)) {
                            toast.error('이미 추가된 도서관이에요.')
                            return
                        }

                        if (library) {
                            setLibraries([
                                ...libraries,
                                {
                                    id: library.id,
                                    logo: library.logo,
                                    name: library.name,
                                }
                            ])
                        }
                    }}

                    isShouldUpdateQueryParam={false}
                />

                <RowSpacer/>

                <div className={'row'}>
                    {
                        libraries.map(library =>
                            <div key={library.id} className={'col-12 col-md-4 col-lg-3 col-xl-2'}>
                                <Card className={'mb-3'}>
                                    <CardBodyContentContainer>
                                        <div style={{position: 'absolute', top: '-5px', right: '0'}} onClick={() => {
                                            setLibraries(
                                                libraries.filter(r => r.id !== library.id)
                                            )
                                        }}>
                                            <AddDeleteButton state={'DELETE'}/>
                                        </div>

                                        <div className={'d-flex justify-content-between align-items-center'}>
                                            <img src={library.logo} className={'img-fluid'} style={{height: '40px'}}/>
                                            <h6 className={'mt-2 fw-bold clamp-1-line'}>{library.name}</h6>
                                        </div>
                                    </CardBodyContentContainer>
                                </Card>
                            </div>
                        )
                    }
                </div>
            </SpacerContainer>}/>
        </RouteContainer>
    )
}

interface Props {
    title: string
    body: JSX.Element
    height?: number
}

const SearchSettingsCard: React.FC<Props> = ({title, body, height}) => {
    return (
        <>
            <Card style={{minHeight: `${height ?? 130}px`}}>
                <CardBodyContentContainer>
                    <div className={'row justify-content-center m-0'}>
                        <h4 className={'mb-3'}>{title}</h4>

                        {body}
                    </div>
                </CardBodyContentContainer>
            </Card>
            <RowSpacer/>
        </>
    )
}

const SpacerContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export default SettingsSearchRoute
