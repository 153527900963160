import React from "react";
import {Spinner} from "react-bootstrap";

interface Props {
    color?: 'book' | 'white'
    size?: 'sm' | undefined
}

const LoadingSpin: React.FC<Props> = ({color = 'book', size = 10}) => {
    return (
        <Spinner animation="border" variant={color} role="status" size={size ? 'sm' : undefined}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>)
}

export default LoadingSpin
