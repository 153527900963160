import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import Modal from "../../../common/Modal";
import {Form as BoostrapForm} from "react-bootstrap";
import RowSpacer from "../../../common/styles/RowSpacer";
import BooksitoutImages from "../../../images/BooksitoutImages";
import BooksitoutIcon from "../../../config/BooksitoutIcon";
import ColorConfig from "../../../config/ColorConfig";
import {BooksitoutServer} from "../../../config/BooksitoutServer";

interface Props {
    isOpen: boolean
    onClose: () => void
    cover: string | null
    setCover: (cover: string | null) => void

    currentCover: string | null
    title: string | null
    author: string | null
}

const BookEditCoverModal: React.FC<Props> = ({isOpen, onClose, cover, setCover, currentCover, title, author}) => {
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const queries = [title, author].filter((query): query is string => query !== null)

        if (queries.length > 0) {
            setIsLoading(true)

            const requests = queries
                .filter((query, index, self) => query && query !== '' && self.indexOf(query) === index)
                .map((query) =>
                    BooksitoutServer.get(`/v1/book/search/cover?q=${encodeURIComponent(query)}`)
                )

            Promise.all(requests)
                .then((responses) => {
                    const allImageUrls = Array.from(new Set(responses.flatMap((res) => res.data)))
                    setImageUrls([currentCover, ...allImageUrls])
                })
                .finally(() => setIsLoading(false))
        } else {
            setImageUrls([])
            setIsLoading(false)
        }
    }, [title, author])

    const onClick = (coverUrl: string) => {
        onClose()
        setCover(coverUrl)
    }

    return (
        <Modal
            titleText={`책 표지 검색`}
            isShowing={isOpen}
            onClose={onClose}
            size={'xl'}
            body={
                <Form>
                    <Row>
                        {
                            isLoading ?
                                Array.from({length: 6}).map(() =>
                                    <Col6>
                                        <BookCover src={BooksitoutImages.Placeholder.bookCoverLoading}
                                                   alt="Book cover"/>
                                        <RowSpacer size={10}/>
                                    </Col6>
                                )
                                :
                                imageUrls.map(imageUrl =>
                                    <Col6 onClick={() => onClick(imageUrl)}>
                                        <BookCover src={imageUrl} alt="Book cover"
                                                   className={`${imageUrl == cover && 'opacity-50'}`}/>
                                        {imageUrl == cover && <CheckMark/>}
                                        <RowSpacer size={10}/>
                                    </Col6>
                                )
                        }
                    </Row>
                </Form>
            }
        />
    )
}

const Form = styled(BoostrapForm)`
    min-height: 300px;
`

const Row = styled.div.attrs({
    className: 'row'
})`
    justify-content: center;
`

const Col6 = styled.div.attrs({
    className: 'col-6 col-md-4'
})`
    position: relative;
`

const BookCover = styled.img.attrs({
    className: 'img-fluid rounded clickable'
})``

const CheckMark = styled(BooksitoutIcon.check)`
    font-size: 50px;
    color: ${ColorConfig.Primary};
    position: absolute;
    left: 0;
`

export default BookEditCoverModal
