import React, {ChangeEvent} from 'react'
import {Form} from "react-bootstrap";
import {BookForm, BookSource} from "../../../routes/book/BookType";

interface Props {
    form: BookForm
    setForm: (form: BookForm) => void
    setSource: (source: BookSource) => void
}

const BookFormFormSelect: React.FC<Props> = ({form, setForm, setSource}) => {
    const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setForm(e.target.value as BookForm)
        setSource('BUY_NEW_OFFLINE')
    }

    return (
        <Form.Select value={form} onChange={onChange}>
            <option value="PHYSICAL">📃 종이책</option>
            <option value="EBOOK">🔌 전자책</option>
            <option value="AUDIO">👂 오디오북</option>
        </Form.Select>
    )
}

export default BookFormFormSelect
