import styled from 'styled-components';
import {Card} from "react-bootstrap";
import CardTitle from "../../../../common/styles/CardTitle";
import StatisticsTableRow from "../../../../common/StatisticsTableRow";

import korean from '../../../../images/book-classifications/languages/korea.png'
import english from '../../../../images/book-classifications/languages/usa.png'
import japanese from '../../../../images/book-classifications/languages/japan.png'
import chinese from '../../../../images/book-classifications/languages/china.png'
import arabic from '../../../../images/book-classifications/languages/arab.png'
import spanish from '../../../../images/book-classifications/languages/spain.png'
import french from '../../../../images/book-classifications/languages/france.png'
import german from '../../../../images/book-classifications/languages/german.png'
import russian from '../../../../images/book-classifications/languages/russia.png'
import useBookStatisticsLanguage from '../hooks/useBookStatisticsLanguage';
import StatisticsTable from "../../../../common/StatisticsTable";

const BookStatisticsLanguageCard = () => {
    const [languageStatistics, totalCount, isLoading] = useBookStatisticsLanguage()

    const getPercent = (count: number | undefined, totalCount: number): string => {
        const validCount = count ?? 0;
        const percentageRaw = totalCount > 0 ? (validCount / totalCount) * 100 : 0;
        const precision = percentageRaw < 1 ? 1 : 0;
        const percentage = percentageRaw.toFixed(precision)

        if (validCount === 0) {
            return ''
        }

        return `${percentage}%`
    }

    const rows: StatisticsTableRow[] = [
        {
            id: 0,
            icon: korean,
            name: '한국어',
            value: languageStatistics?.korean?.toString() ?? '-',
            extraValue: getPercent(languageStatistics?.korean, totalCount)
        },
        {
            id: 1,
            icon: english,
            name: '영어',
            value: languageStatistics?.english?.toString() ?? '-',
            extraValue: getPercent(languageStatistics?.english, totalCount)
        },
        {
            id: 2,
            icon: japanese,
            name: '일본어',
            value: languageStatistics?.japanese?.toString() ?? '-',
            extraValue: getPercent(languageStatistics?.japanese, totalCount)
        },
        {
            id: 3,
            icon: chinese,
            name: '중국어',
            value: languageStatistics?.chinese?.toString() ?? '-',
            extraValue: getPercent(languageStatistics?.chinese, totalCount)
        },
        {
            id: 4,
            icon: arabic,
            name: '아랍어',
            value: languageStatistics?.arabic?.toString() ?? '-',
            extraValue: getPercent(languageStatistics?.arabic, totalCount)
        },
        {
            id: 5,
            icon: spanish,
            name: '스페인어',
            value: languageStatistics?.spanish?.toString() ?? '-',
            extraValue: getPercent(languageStatistics?.spanish, totalCount)
        },
        {
            id: 6,
            icon: french,
            name: '프랑스어',
            value: languageStatistics?.french?.toString() ?? '-',
            extraValue: getPercent(languageStatistics?.french, totalCount)
        },
        {
            id: 7,
            icon: german,
            name: '독일어',
            value: languageStatistics?.german?.toString() ?? '-',
            extraValue: getPercent(languageStatistics?.german, totalCount)
        },
        {
            id: 8,
            icon: russian,
            name: '러시아어',
            value: languageStatistics?.russian?.toString() ?? '-',
            extraValue: getPercent(languageStatistics?.russian, totalCount)
        },
    ].sort((a, b) => parseFloat(b.value) - parseFloat(a.value))

    return (
        <Container>
            <ContainerBody>
                <CardTitle icon={<></>} title={`언어별 독서 요약`} url={''}/>

                <div className={'h-100'}>
                    <div className={'d-flex align-items-center'} style={{marginBottom: '10px'}}>
                        <StatisticsTable isLoading={isLoading} rows={rows}/>
                    </div>
                </div>
            </ContainerBody>
        </Container>
    )
}

const Container = styled(Card)`
    height: 100%;
    min-height: 650px;
`

const ContainerBody = styled(Card.Body)`
    height: 100%;
`

export default BookStatisticsLanguageCard
