import {IconType} from 'react-icons';

import {
    BsBookHalf,
    BsFileEarmarkBarGraphFill,
    BsFillCalendarFill,
    BsFillChatLeftTextFill,
    BsFillFileImageFill,
    BsFillInfoCircleFill,
    BsFillPatchCheckFill,
    BsFillPeopleFill,
    BsFillPersonVcardFill,
    BsFillPostcardFill,
    BsFillQuestionCircleFill,
    BsFire,
    BsGearFill,
    BsMapFill,
    BsPencilFill,
    BsPeopleFill,
    BsTrainFrontFill,
    BsWebcamFill,
} from 'react-icons/bs'
import {
    FaCross,
    FaMinusCircle,
    FaPeopleArrows,
    FaPlusCircle,
    FaSearch,
    FaTheaterMasks,
    FaUserAlt,
    FaArrowDown,
    FaArrowUp
} from 'react-icons/fa'
import {TbBookOff, TbLocationFilled, TbTargetArrow} from 'react-icons/tb'
import {FiLogIn} from 'react-icons/fi'
import {GrCircleQuestion} from 'react-icons/gr'
import {HiDotsCircleHorizontal, HiOutlineUserAdd, HiPencilAlt} from 'react-icons/hi'
import {ImLibrary, ImSpinner11} from 'react-icons/im'
import {BiNetworkChart, BiSearchAlt2, BiTime, BiTransfer, BiExit} from 'react-icons/bi'
import {
    AiFillCheckCircle,
    AiFillDislike,
    AiFillExperiment,
    AiFillLike,
    AiFillNotification,
    AiFillPicture,
    AiFillStar,
    AiOutlineAppstore,
    AiOutlineStar
} from 'react-icons/ai'
import {RiArrowGoBackFill, RiComputerFill, RiFilePaperLine, RiFunctionFill} from 'react-icons/ri'
import {
    MdBrowserNotSupported,
    MdCancel,
    MdEmail,
    MdFeedback,
    MdHistoryEdu,
    MdLanguage,
    MdOutlineEmojiPeople,
    MdPrivacyTip,
    MdQuiz, MdRateReview
} from 'react-icons/md';
import {GiEvilBook, GiThink} from 'react-icons/gi';
import {IoReloadCircle} from 'react-icons/io5';


const booksitoutIcon: { [key: string]: IconType } = {
    login: FiLogIn,
    join: HiOutlineUserAdd,
    question: GrCircleQuestion,
    user: FaUserAlt,

    // settings
    settings: BsGearFill,
    paid: BsFillPatchCheckFill,

    // pwa
    pwa: AiOutlineAppstore,
    notSupported: MdBrowserNotSupported,
    info: BsFillInfoCircleFill,

    location: TbLocationFilled,
    map: BsMapFill,

    search: FaSearch,
    topnavSearch: BiSearchAlt2,

    book: BsBookHalf,
    title: BsPencilFill,
    author: BsFillPeopleFill,
    description: BsFillChatLeftTextFill,
    publishYear: BsFillCalendarFill,
    page: RiFilePaperLine,
    giveUp: BiExit,
    unGiveUp: ImSpinner11,
    review: MdRateReview,

    // Statistics
    statistics: BsFileEarmarkBarGraphFill,
    star: AiOutlineStar,
    starFill: AiFillStar,
    goal: TbTargetArrow,
    language: MdLanguage,
    category: FaTheaterMasks,
    consecutive: BsFire,
    currentReading: MdOutlineEmojiPeople,

    categoryOthers: HiDotsCircleHorizontal,
    categoryPhilosophy: GiThink,
    categoryReligion: FaCross,
    categoryNaturalScience: AiFillExperiment,
    categoryLanguage: MdLanguage,
    categoryLiterature: GiEvilBook,
    categoryHistory: MdHistoryEdu,

    categoryArt: AiFillPicture,
    categoryTechnology: RiComputerFill,
    categorySocialScience: BiNetworkChart,

    // Community
    community: BsPeopleFill,
    popular: BsFire,
    post: BsFillPostcardFill,
    quiz: MdQuiz,
    image: BsFillFileImageFill,
    like: AiFillLike,
    dislike: AiFillDislike,

    check: AiFillCheckCircle,
    xmark: MdCancel,

    // gathering
    gathering: FaPeopleArrows,
    subway: BsTrainFrontFill,
    online: BsWebcamFill,
    others: BsFillQuestionCircleFill,

    // tips
    time: BiTime,

    // library
    library: ImLibrary,
    membership: BsFillPersonVcardFill,

    // admin
    faq: BsFillQuestionCircleFill,
    suggestion: MdFeedback,

    convert: BiTransfer,

    feature: RiFunctionFill,

    privacy: MdPrivacyTip,

    notification: AiFillNotification,
    email: MdEmail,

    reload: IoReloadCircle,

    bookXmark: TbBookOff,

    // add: MdAddCircle,
    // add: FaCirclePlus,
    add: FaPlusCircle,
    delete: FaMinusCircle,
    edit: HiPencilAlt,
    back: RiArrowGoBackFill,

    ArrowDown: FaArrowDown,
    ArrowUp: FaArrowUp,
}

export default booksitoutIcon