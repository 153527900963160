import {useEffect, useState} from "react"
import BookStatisticsCategoryResponse from "../responses/BookStatisticsCategoryResponse";
import {BooksitoutServer} from "../../../../config/BooksitoutServer";

const useBookStatisticsCategory = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [categories, setCategories] = useState<BookStatisticsCategoryResponse | null>(null)
    const [totalCount, setTotalCount] = useState<number>(0)

    useEffect(() => {
        BooksitoutServer
            .get(`/v1/statistics/categories`)
            .then((res) => setCategories(res.data))
            .finally(() => setIsLoading(false))
    }, [])

    useEffect(() => {
        setTotalCount(
            (categories?.othersCount ?? 0) +
            (categories?.philosophyCount ?? 0) +
            (categories?.religionCount ?? 0) +
            (categories?.socialScienceCount ?? 0) +
            (categories?.naturalScienceCount ?? 0) +
            (categories?.technologyCount ?? 0) +
            (categories?.artCount ?? 0) +
            (categories?.languageCount ?? 0) +
            (categories?.literatureCount ?? 0) +
            (categories?.historyCount ?? 0) +
            (categories?.unknownCount ?? 0)
        )
    }, [categories])

    return [categories, totalCount, isLoading] as const
}

export default useBookStatisticsCategory