import styled from 'styled-components';
import ColSpacer from "../../../common/styles/ColSpacer";
import Spacer from "../../../common/Spacer";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import BooksitoutImages from "../../../images/BooksitoutImages";
import LoadingBar from '../../../common/LoadingBar';

const ProfileListItemLoading = () => {
    return (
        <CardBodyContentContainer>
            <ProfileContainer>
                <ProfileImage src={BooksitoutImages.Placeholder.user} alt={'loading'}/>

                <ColSpacer/>

                <ProfileInfoContainer>
                    <ProfileName><LoadingBar size={10}/></ProfileName>

                    <ProfileDate>
                        <LoadingBar size={1}/>년
                        {' '}
                        <LoadingBar size={1}/>월
                        {' '}
                        <LoadingBar size={1}/>일
                    </ProfileDate>
                </ProfileInfoContainer>

                <Spacer/>

                <BookListContainer>
                    {
                        Array.from({length: 7}).map(_ =>
                            <BookCover src={BooksitoutImages.Placeholder.bookCoverLoading}
                                       alt={'placeholder book cover'}/>
                        )
                    }
                </BookListContainer>
            </ProfileContainer>
        </CardBodyContentContainer>
    )
}

const ProfileContainer = styled.div`
    display: flex;
`

const ProfileImage = styled.img.attrs({
    className: 'rounded'
})`
    width: 50px;
    height: 50px;
    cursor: wait;
`

const ProfileInfoContainer = styled.div`
    max-width: 200px;
`

const ProfileName = styled.h1.attrs({
    className: 'clamp-1-line'
})`
    font-size: 1.5rem;
    font-weight: bold;
`

const ProfileDate = styled.h1.attrs({
    className: 'text-secondary'
})`
    display: flex;
    font-size: 1rem;
`

const BookListContainer = styled.div`
    padding-left: 10px;
`

const BookCover = styled.img.attrs({
    className: 'rounded d-none d-md-inline'
})`
    height: 60px;
    padding-left: 3px;
    padding-right: 3px;
    cursor: wait;
`

export default ProfileListItemLoading
