import React from 'react'
import {Form} from "react-bootstrap";
import {BookForm, BookSource} from "../../../routes/book/BookType";

interface Props {
    form: BookForm
    source: BookSource
    setSource: (source: BookSource) => void
}

const BookSourceFormSelect: React.FC<Props> = ({form, source, setSource}) => {
    return (
        <Form.Select value={source} onChange={(e) => setSource(e.target.value as BookSource)}>
            {
                form == 'PHYSICAL' ?
                    <>
                        <option value="BUY_NEW_OFFLINE">새 책 구입 (오프라인)</option>
                        <option value="BUY_NEW_ONLINE">새 책 구입 (온라인)</option>
                        <option value="BUY_USED_OFFLINE">중고 책 구입 (오프라인)</option>
                        <option value="BUY_USED_ONLINE">중고 책 구입 (온라인)</option>
                        <option value="LIBRARY">도서관</option>
                        <option value="BORROW_STORE">대여(서점)</option>
                        <option value="BORROW_FRIENDS">대여(친구)</option>
                        <option value="SUBSCRIPTION">구독</option>
                        <option value="OTHERS">기타</option>
                    </> :
                    form == 'EBOOK' ?
                        <>
                            <option value="BUY_NEW_ONLINE">구입</option>
                            <option value="LIBRARY">도서관</option>
                            <option value="BORROW_FRIENDS">대여(친구)</option>
                            <option value="SUBSCRIPTION">구독</option>
                            <option value="OTHERS">기타</option>
                        </>
                        :
                        <>
                            <option value="BUY_NEW_ONLINE">구입</option>
                            <option value="LIBRARY">도서관</option>
                            <option value="BORROW_FRIENDS">대여(친구)</option>
                            <option value="SUBSCRIPTION">구독</option>
                            <option value="OTHERS">기타</option>
                        </>
            }
        </Form.Select>
    )
}
export default BookSourceFormSelect
