import styled from 'styled-components';
import IndexContentContainer from "../../../index/IndexContentContainer";
import CardTitle from "../../../../common/styles/CardTitle";
import BooksitoutIcon from "../../../../config/BooksitoutIcon";
import useBookDetailStore from "../useBookDetailStore";
import BookSourceNoContent from "../../../search/bookSource/BookSourceNoContent";
import {useState} from "react";
import BookDetailReviewModal from "./BookDetailReviewModal";
import parse from "html-react-parser";

const BookDetailReviewCard = () => {
    const {book} = useBookDetailStore()
    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
        <IndexContentContainer>
            <BookDetailReviewModal isOpen={isOpen} close={() => setIsOpen(false)}/>

            <Container onClick={() => setIsOpen(true)}>
                <CardTitle icon={<BooksitoutIcon.review/>} title={`내 감상`} url={``}/>

                {
                    (book?.review == null || book.review === '') ?
                        <NoContent/>
                        :
                        <>
                            {parse(book?.review)}
                        </>
                }
            </Container>
        </IndexContentContainer>
    )
}

const NoContent = () => {
    return (
        <div className={'d-flex justify-content-center w-100'}>
            <BookSourceNoContent message={`추가한 감상이 없어요. 여기를 클릭해서 추가할 수 있어요.`}/>
        </div>
    )
}

const Container = styled.div`
    width: 100%;
    min-height: 200px;
    
    &:hover {
        color: black;
    }
`

export default BookDetailReviewCard
