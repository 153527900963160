import React from 'react'
import styled from 'styled-components';
import StatisticsTableRow from "./StatisticsTableRow";
import Spacer from "./Spacer";
import LoadingBar from "./LoadingBar";

interface Props {
    isLoading: boolean
    rows: StatisticsTableRow[]
}

const StatisticsTable: React.FC<Props> = ({isLoading, rows}) => {
    return (
        <Table>
            <tbody>
            {
                rows.map((row) => (
                    <tr>
                        <th className={'col-8 h5'}>
                            <Image src={row.icon} alt={''}/>
                            {row.name}
                        </th>

                        <td className={'col-4'}>
                            <h5 className={'d-flex flex-row'}>
                                {
                                    isLoading ?
                                        <div style={{marginTop: '2px', marginBottom: '2px'}}>
                                            <LoadingBar size={3}/>
                                        </div>
                                        :
                                        <>
                                            {row.value}
                                            {
                                                row.extraValue && <>
                                                    <Spacer/>
                                                    <ExtraValue>{row.extraValue}</ExtraValue>
                                                </>
                                            }
                                        </>
                                }
                            </h5>
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </Table>
    )
}

const Table = styled.table.attrs({
    className: 'table table-hover'
})`
    margin: 0;
`;

const Image = styled.img.attrs({
    className: 'img-fluid me-3 force-1-line'
})`
    width: 30px;
    height: 30px;
`;

const ExtraValue = styled.div.attrs({
    className: 'text-secondary'
})`
    padding-top: 7px;
    margin: 0;
    font-size: 14px;
`

export default StatisticsTable
