import {useEffect, useState} from 'react'
import {useParams} from 'react-router';
import BooksitoutProductType from "./type/BooksitoutProductType";
import NoContent from "../common/NoContent";
import RowSpacer from "../common/styles/RowSpacer";
import ProductDetailBasicInfo from './productDetail/ProductDetailBasicInfo';
import ProductDetailDescriptionCard from './productDetail/ProductDetailDescriptionCard';
import ProductDetailFeaturesCard from './productDetail/ProductDetailFeaturesCard';
import ProductDetailDemoCard from './productDetail/ProductDetailDemoCard';
import ProductDetailPrivacyCard from './productDetail/ProductDetailPrivacyCard';
import ProductDetailLanguageCard from "./productDetail/ProductDetailLanguageCard";
import RouteContainer from "../common/styles/RouteContainer";
import useLanguage from "../common/language/useLanguage";
import getLanguageValue from "../common/language/LanguageInfo";
import BooksitoutProductsData from "../data/products/BooksitoutProductsData";

const BooksitoutProductDetailRoute = () => {
    const language = useLanguage()
    const {productName} = useParams()

    let NO_CONTENT = getLanguageValue('BooksitoutProductsDetailRoute.NotFound', language)

    const [product, setProduct] = useState<BooksitoutProductType | null>(null)
    useEffect(() => {
        const productCandidate = BooksitoutProductsData.filter((p) => p.name.url.toUpperCase() === productName?.toUpperCase())

        if (productCandidate.length != 0) {
            setProduct(productCandidate[0])
            let productName = getLanguageValue(`Products.${productCandidate[0].id}.name`, language) ?? '?'

            const korean = `소개 | ${productName}`
            const japanese = `紹介 | ${productName}`
            const english = `Introduction | ${productName}`

            switch (language) {
                case 'KOREAN':
                    document.title = korean
                    break
                case 'JAPANESE':
                    document.title = japanese
                    break
                default:
                    document.title = english
                    break
            }
        }
    }, [productName, language])

    if (product === null) {
        return <NoContent message={NO_CONTENT}/>
    }

    return (
        <RouteContainer>
            <RowSpacer/>

            <ProductDetailBasicInfo product={product}/>
            <RowSpacer/>

            <ProductDetailDescriptionCard product={product}/>
            <RowSpacer/>

            <ProductDetailFeaturesCard product={product}/>
            <RowSpacer/>

            <ProductDetailDemoCard product={product}/>
            <RowSpacer/>

            <ProductDetailPrivacyCard product={product}/>
            <RowSpacer/>

            <ProductDetailLanguageCard product={product}/>
            <RowSpacer/>
        </RouteContainer>
    )
}

export default BooksitoutProductDetailRoute