import BooksitoutDemoImage from "../demos/BooksitoutDemoImage";
import BooksitoutProductType from "../../../booksitout/type/BooksitoutProductType";
import BooksitoutProductStatus from "../../../booksitout/type/BooksitoutProductStatus";
import BooksitoutImages from "../../../images/BooksitoutImages";

const booksitoutProductPianoman: BooksitoutProductType = {
	id: 'pianoman',
	status: BooksitoutProductStatus.PRODUCTION,
	name: {
		english: 'Pianoman',
		url: 'piano-man',
	},
	icon: BooksitoutImages.WebsiteLogo.pianoman,
	link: [
		{
			link: 'https://apps.apple.com/us/app/pianoman/id6456449788',
			icon: BooksitoutImages.WebsiteLogo.appStore,
			border: false,
		},
	],
	thumbnailImage: BooksitoutDemoImage.pianoman,
	detailImage: BooksitoutDemoImage.pianoman,
	featuresCount: 0,
	demoImages: [],
	privacyPolicyDates: ['2025-01-27'],
}

export default booksitoutProductPianoman