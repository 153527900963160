import {useEffect, useState} from "react";
import {BooksitoutServer} from "../../../config/BooksitoutServer";
import CurrentReadingSessionResponse from "./CurrentReadingSessionResponse";
import useLoginStore from "../../login/useLoginStore";

const useCurrentReadingSession = () => {
    const [currentReadingSession, setCurrentReadingSession] = useState<CurrentReadingSessionResponse | null>(null)
    useEffect(() => {
        refresh()
    }, [])

    const isLoggedIn = useLoginStore((state) => state.isLoggedIn())

    const refresh = () => {
        if (isLoggedIn) {
            BooksitoutServer
                .get(`/v1/book/reading-session/current`)
                .then((res) => setCurrentReadingSession(res.data))
        }
    }

    return {currentReadingSession, refresh}
}

export default useCurrentReadingSession