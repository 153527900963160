import React from 'react'
import styled from 'styled-components';
import {Card} from 'react-bootstrap'
import BooksitoutProductType from "../type/BooksitoutProductType";
import {BooksitoutCard, BooksitoutCardTitle} from "../BooksitoutCard";
import ColorConfig from "../../config/ColorConfig";
import useLanguage from "../../common/language/useLanguage";
import getLanguageValue from "../../common/language/LanguageInfo";

interface Props {
    product: BooksitoutProductType
}

const ProductDetailPrivacyCard: React.FC<Props> = ({product}) => {
    const language = useLanguage()

    let TITLE = getLanguageValue(`ProductDetailPrivacyCard.Title`, language)

    return (
        <BooksitoutCard>
            <Card.Body>
                <BooksitoutCardTitle>{TITLE}</BooksitoutCardTitle>

                <ul>
                    {
                        product.privacyPolicyDates.map(privacyPolicy =>
                            <li>
                                <Link
                                    href={`/products/${product.name.url}/privacy/${privacyPolicy}?language=${language}`}>{privacyPolicy}</Link>
                            </li>
                        )
                    }
                </ul>
            </Card.Body>
        </BooksitoutCard>
    )
}

const Link = styled.a`
    text-decoration: none;
    color: ${ColorConfig.Primary};
    font-weight: bold;

    &:hover {
        color: var(--primary-color);
    }
`

export default ProductDetailPrivacyCard